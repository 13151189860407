/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { Fragment, h, VNode } from "preact";
import { useEffect, useState } from "preact/hooks";
// import { strings as messages } from "../i18n/strings.js";
import langIcon from "../assets/lang.svg";
import { useTranslationContext } from "../index.browser.js";

type LangsNames = {
  [P: string]: string;
};

const names: LangsNames = {
  uk: "Українська [uk]",
  tr: "Türkçe [tr]",
  ru: "Ру́сский язы́к [ru]",
  sv: "Svenska [sv]",
  it: "Italiano [it]",
  fr: "Français [fr]",
  es: "Español [es]",
  de: "Deutsch [de]",
  en: "English [en]",
};

function getLangName(s: keyof LangsNames | string): string {
  if (names[s]) return names[s];
  return String(s);
}

export function LangSelector({
  type = "select",
}: {
  type?: "select" | "icon";
}): VNode {
  const { lang, changeLanguage, completeness, supportedLang } =
    useTranslationContext();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    function bodyKeyPress(event: KeyboardEvent) {
      if (event.code === "Escape") setHidden(true);
    }
    function bodyOnClick(event: Event) {
      setHidden(true);
    }
    document.body.addEventListener("click", bodyOnClick);
    document.body.addEventListener("keydown", bodyKeyPress as any);
    return () => {
      document.body.removeEventListener("keydown", bodyKeyPress as any);
      document.body.removeEventListener("click", bodyOnClick);
    };
  }, []);
  return (
    <Fragment>
      {(function () {
        switch (type) {
          case "select": {
            return (
              <button
                type="button"
                class="relative w-full   rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                onClick={(e) => {
                  setHidden(!hidden);
                  e.stopPropagation();
                }}
              >
                <span class="flex items-center">
                  <img
                    alt="language"
                    class="h-5 w-5 flex-shrink-0 rounded-full"
                    src={langIcon}
                  />
                  <span class="ml-3 block truncate">{getLangName(lang)}</span>
                </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <svg
                    class="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            );
          }
          case "icon": {
            return (
              <button
                type="button"
                class="relative w-full  rounded-md bg-white p-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                onClick={(e) => {
                  setHidden(!hidden);
                  e.stopPropagation();
                }}
              >
                <div class="flex h-7 w-7">
                  <img
                    alt="language"
                    class="h-7 w-7 flex-shrink-0 rounded-full"
                    src={langIcon}
                  />
                  {/* {lang} */}
                </div>
              </button>
            );
          }
        }
      })()}

      {!hidden && (
        <ul
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          style={type === "icon" ? { right: 0, width: 200 } : {}}
          tabIndex={-1}
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          {type === "icon" ? (
            <Fragment>
              <li
                class="text-gray-900 relative border-b boder-gray-200 select-none py-2 pl-3 pr-9"
                role="option"
              >
                <span class="font-normal truncate flex justify-between ">
                  <span>{getLangName(lang)}</span>
                  <span>{(completeness as any)[lang]}%</span>
                </span>

                <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  {/* <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                  </svg> */}
                </span>
              </li>
            </Fragment>
          ) : (
            <Fragment />
          )}
          {Object.keys(supportedLang)
            .filter((l) => l !== lang)
            .map((lang) => (
              <li
                class="text-gray-900 hover:bg-indigo-600 hover:text-white cursor-pointer relative select-none py-2 pl-3 pr-9"
                role="option"
                onClick={() => {
                  changeLanguage(lang);
                  setHidden(true);
                }}
              >
                <span class="font-normal truncate flex justify-between ">
                  <span>{getLangName(lang)}</span>
                  <span>{(completeness as any)[lang]}%</span>
                </span>

                <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  {/* <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                      </svg> */}
                </span>
              </li>
            ))}
        </ul>
      )}
      {/* <div>
        <div class="relative mt-2">
        </div>
    </div> */}
    </Fragment>
  );
}
