/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  countryNamesByCode,
  countryNationalitiesByCode,
  currencyNamesByCode,
  langNamesByCode,
} from "@gnu-taler/taler-util";
import { InternationalizationAPI, SelectUiChoice } from "../index.browser.js";

export function currencyNameList(
  i18n: InternationalizationAPI,
): SelectUiChoice[] {
  return Object.entries(currencyNamesByCode).map(([value, translator]) => ({
    value,
    label: translator(i18n),
  }));
}

export function countryNameList(
  i18n: InternationalizationAPI,
): SelectUiChoice[] {
  return Object.entries(countryNamesByCode).map(([value, translator]) => ({
    value,
    label: translator(i18n),
  }));
}

export function countryNationalityList(
  i18n: InternationalizationAPI,
): SelectUiChoice[] {
  return Object.entries(countryNationalitiesByCode).map(
    ([value, translator]) => ({
      value,
      label: translator(i18n),
    }),
  );
}

export function languageNameList(
  i18n: InternationalizationAPI,
): SelectUiChoice[] {
  return Object.entries(langNamesByCode).map(([value, translator]) => ({
    value,
    label: translator(i18n),
  }));
}
