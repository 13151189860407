/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  OperationAlternative,
  OperationFail,
  OperationOk,
  OperationResult,
  TranslatedString,
} from "@gnu-taler/taler-util";
import { Fragment, VNode, h } from "preact";
import { HTMLAttributes, useState } from "preact/compat";
import { useTranslationContext } from "../index.browser.js";
// import { useBankCoreApiContext } from "../context/config.js";

// function errorMap<T extends OperationFail<unknown>>(resp: T, map: (d: T["case"]) => TranslatedString): void {

export type OnOperationSuccesReturnType<T> = (
  result: T extends OperationOk<any> ? T : never,
) => TranslatedString | void;
export type OnOperationFailReturnType<T> = (
  d:
    | (T extends OperationFail<any> ? T : never)
    | (T extends OperationAlternative<any, any> ? T : never),
) => TranslatedString;

export interface ButtonHandler<T extends OperationResult<A, B>, A, B> {
  onClick: () => Promise<T | undefined>;
}

interface Props<T extends OperationResult<A, B>, A, B>
  extends HTMLAttributes<HTMLButtonElement> {
  handler: ButtonHandler<T, A, B> | undefined;
}

/**
 * This button accept an async function and report a notification
 * on error or success.
 *
 * When the async function is running the inner text will change into
 * a "loading" animation.
 *
 * @param param0
 * @returns
 */
export function Button<T extends OperationResult<A, B>, A, B>({
  handler,
  children,
  disabled,
  onClick: clickEvent,
  ...rest
}: Props<T, A, B>): VNode {
  const { i18n } = useTranslationContext();
  const [running, setRunning] = useState(false);
  return (
    <button
      {...rest}
      disabled={disabled || running}
      onClick={(e) => {
        e.preventDefault();
        if (!handler) {
          return;
        }
        setRunning(true);
        handler.onClick().finally(() => {
          setRunning(false);
        });
      }}
    >
      {running ? <Wait /> : children}
    </button>
  );
}

function Wait(): VNode {
  return (
    <Fragment>
      <style>
        {`
      #l1 {          width: 120px;
        height: 20px;
        -webkit-mask: radial-gradient(circle closest-side, currentColor 90%, #0000) left/20% 100%;
        background: linear-gradient(currentColor 0 0) left/0% 100% no-repeat #ddd;
        animation: l17 2s infinite steps(6);
      }
      @keyframes l17 {
          100% {background-size:120% 100%}
`}
      </style>
      <div id="l1" />
    </Fragment>
  );
}
