import { useTranslationContext } from "../index.browser.js";
import { h } from "preact";

export function Footer({
  testingUrlKey,
  VERSION,
  GIT_HASH,
}: {
  VERSION?: string;
  GIT_HASH?: string;
  testingUrlKey?: string;
}) {
  const { i18n } = useTranslationContext();

  const testingUrl =
    testingUrlKey &&
    typeof localStorage !== "undefined" &&
    localStorage.getItem(testingUrlKey)
      ? localStorage.getItem(testingUrlKey) ?? undefined
      : undefined;
  const versionText = VERSION ? (
    GIT_HASH ? (
      <a
        href={`https://git.taler.net/wallet-core.git/tree/?id=${GIT_HASH}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        Version {VERSION} ({GIT_HASH.substring(0, 8)})
      </a>
    ) : (
      VERSION
    )
  ) : (
    ""
  );
  return (
    <footer class="bottom-4 my-4 mx-8 bg-slate-200">
      <div>
        <p class="text-xs leading-5 text-gray-400">
          <i18n.Translate>
            Learn more about{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              class="font-semibold text-gray-500 hover:text-gray-400"
              href="https://taler.net"
            >
              GNU Taler
            </a>
          </i18n.Translate>
        </p>
      </div>
      <div style="flex-grow:1" />
      <p class="text-xs leading-5 text-gray-400">
        Copyright &copy; 2014&mdash;2025 Taler Systems SA. {versionText}{" "}
      </p>
      {testingUrlKey && testingUrl && (
        <p class="text-xs leading-5 text-gray-300">
          Testing with {testingUrl}{" "}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              localStorage.removeItem(testingUrlKey);
              window.location.reload();
            }}
          >
            stop testing
          </a>
        </p>
      )}
    </footer>
  );
}
