import { TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { RenderAddon } from "./InputLine.js";
import { Addon } from "../FormProvider.js";

interface Props {
  label: TranslatedString;
  url: string;
  media?: string;
  tooltip?: TranslatedString;
  help?: TranslatedString;
  before?: Addon;
  after?: Addon;
}

export function ExternalLink({
  before,
  after,
  label,
  url,
  media,
  tooltip,
  help,
}: Props): VNode {
  return (
    <div class="sm:col-span-6">
      {before !== undefined && <RenderAddon addon={before} />}
      <a
        href={url}
        class="underline text-blue-600 hover:text-blue-900 visited:text-purple-600"
        target="_blank"
        rel="noreferrer"
      >
        {label}
      </a>
      {after !== undefined && <RenderAddon addon={after} />}
      {help && (
        <p class="mt-2 text-sm text-gray-500" id="email-description">
          {help}
        </p>
      )}
    </div>
  );
}
