/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  HttpStatusCode,
  TalerError,
  TalerExchangeHttpClient,
  TalerMerchantApi,
  assertUnreachable,
  parsePaytoUri,
} from "@gnu-taler/taler-util";
import { Fragment, VNode, h } from "preact";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { useInstanceKYCDetails } from "../../../../hooks/instance.js";
import { ListPage } from "./ListPage.js";
import { useState } from "preact/hooks";
import {
  ConfirmModal,
  ValidBankAccount,
} from "../../../../components/modal/index.js";
import { useTransition } from "preact/compat";
import { useTranslationContext } from "@gnu-taler/web-util/browser";

interface Props {
  // onGetInfo: (id: string) => void;
  // onShowInstructions: (id: string) => void;
}

export default function ListKYC(_p: Props): VNode {
  const result = useInstanceKYCDetails();
  const [showingInstructions, setShowingInstructions] = useState<
    TalerMerchantApi.MerchantAccountKycRedirect | undefined
  >(undefined);
  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  /**
   * This component just render known kyc requirements.
   * If query fail then is safe to hide errors.
   */
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.GatewayTimeout: {
        return <div />;
      }
      case HttpStatusCode.BadGateway: {
        break;
        // return (
        //   <ListPage
        //     status={result.body}
        //     onGetInfo={_p.onGetInfo}
        //     onShowInstructions={() => {
        //       setShowingInstructions(true)
        //     }}
        //   />
        // );
      }
      case HttpStatusCode.ServiceUnavailable: {
        return <div />;
      }
      case HttpStatusCode.Unauthorized: {
        return <div />;
      }
      case HttpStatusCode.NotFound: {
        return <div />;
      }
      default: {
        assertUnreachable(result);
      }
    }
  }
  const status = result.body;

  if (!status) {
    return <div>no kyc required</div>;
  }
  return (
    <Fragment>
      {showingInstructions !== undefined ? (
        <ShowInstructionForKycRedirect
          e={showingInstructions}
          onCancel={() => setShowingInstructions(undefined)}
        />
      ) : undefined}
      <ListPage
        status={status}
        // onGetInfo={async (exchange, ac) => {
        //   new URL()
        // }}
        onShowInstructions={(e) => {
          setShowingInstructions(e);
        }}
      />
    </Fragment>
  );
}

function ShowInstructionForKycRedirect({
  e,
  onCancel,
}: {
  e: TalerMerchantApi.MerchantAccountKycRedirect;
  onCancel: () => void;
}): VNode {
  const { i18n } = useTranslationContext();
  switch (e.status) {
    case TalerMerchantApi.MerchantAccountKycStatus.KYC_WIRE_REQUIRED:
      return (
        <ValidBankAccount
          origin={parsePaytoUri(e.payto_uri)!}
          targets={(e.payto_kycauths ?? []).map((d) => parsePaytoUri(d)!)}
          onCancel={onCancel}
        />
      );
    case TalerMerchantApi.MerchantAccountKycStatus.NO_EXCHANGE_KEY: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`No exchange keys`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              The backend service is still synchonizing with the payment service
              provider.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.KYC_WIRE_IMPOSSIBLE: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`Can't wire from this account`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              The payment service provider requires you to make a wire transfer
              using the account provided but this account is not compatible with
              the exchange.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }

    case TalerMerchantApi.MerchantAccountKycStatus.KYC_REQUIRED: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`KYC Required`}
          active
          onCancel={onCancel}
        >
          <a href="#">
            <p style={{ paddingTop: 0 }}>
              <i18n.Translate>
                The payment provider requires information to enable the account.
              </i18n.Translate>
            </p>
          </a>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.AWAITING_AML_REVIEW: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`Awaiting AML review`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              This account is can't be used. The payment service provider is
              doing manual review of the account information.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.READY: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`Ready`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              This account is ready to be used with the payment service
              provider.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.LOGIC_BUG: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`Logic bug`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              The backend service detected an internal error, contact the system
              administrator or check again later.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.EXCHANGE_INTERNAL_ERROR: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`Internal error`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              The payment service provider detected an internal error, contact
              the system administrator or check again later.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.EXCHANGE_GATEWAY_TIMEOUT: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`Logic bug`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              The backend service couldn't contact the payment service provider
              due to a timeout, contact the system administrator or check again
              later.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.EXCHANGE_UNREACHABLE: {
      return (
        <ConfirmModal
          label={i18n.str`Ok`}
          description={i18n.str`Logic bug`}
          active
          onCancel={onCancel}
        >
          <p style={{ paddingTop: 0 }}>
            <i18n.Translate>
              Unable to reach the payment service provider, contact the system
              administrator or check again later.
            </i18n.Translate>
          </p>
        </ConfirmModal>
      );
    }
    case TalerMerchantApi.MerchantAccountKycStatus.EXCHANGE_STATUS_INVALID:
      {
        return (
          <ConfirmModal
            label={i18n.str`Ok`}
            description={i18n.str`Logic bug`}
            active
            onCancel={onCancel}
          >
            <p style={{ paddingTop: 0 }}>
              <i18n.Translate>
                The payment service provider replied with a invalid status,
                contact the system administrator or check again later.
              </i18n.Translate>
            </p>
          </ConfirmModal>
        );
      }
      return <div />;
    default:
      assertUnreachable(e.status);
  }
}
