/*
 This file is part of GNU Taler
 (C) 2025 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU Affero Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU Affero Public License for more details.

 You should have received a copy of the GNU Affero Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { TalerFormAttributes } from "@gnu-taler/taler-util";
import { format } from "date-fns";
import {
  DoubleColumnFormDesign,
  FormMetadata,
  InternationalizationAPI,
} from "../../index.browser.js";

export const form_generic_note = (
  i18n: InternationalizationAPI,
): FormMetadata => ({
  label: i18n.str`Generic note`,
  description: i18n.str`Free-form, generic note`,
  id: "generic_note",
  version: 1,
  config: design_generic_note(i18n),
});

/**
 * Design of the generic note form.
 */
export function design_generic_note(
  i18n: InternationalizationAPI,
): DoubleColumnFormDesign {
  const today = format(new Date(), "yyyy-MM-dd");

  return {
    type: "double-column",
    sections: [
      {
        title: i18n.str`Note / Memorandum`,
        fields: [
          {
            id: TalerFormAttributes.NOTE_TEXT,
            label: i18n.str`Free-form notes`,
            type: "textArea",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Supplemental File Upload`,
        description: i18n.str`Optional supplemental information for the establishment of the business relationship with the customer.`,
        fields: [
          {
            id: TalerFormAttributes.SUPPLEMENTAL_FILES_LIST,
            label: i18n.str`Supplemental Files`,
            type: "array",
            labelFieldId: "FILE.FILENAME",
            required: false,
            fields: [
              {
                id: TalerFormAttributes.DESCRIPTION,
                label: i18n.str`Description`,
                type: "textArea",
                required: true,
              },
              {
                id: TalerFormAttributes.FILE,
                label: i18n.str`File (PDF)`,
                type: "file",
                accept: "application/pdf",
                required: true,
              },
            ],
          },
        ],
      },
    ],
  };
}
