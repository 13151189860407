/*
 This file is part of GNU Taler
 (C) 2025 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { TalerErrorCode } from "./taler-error-codes.js";

/**
 * Result of a generic operation that can fail.
 */
export type Result<T, Err> = ResultOk<T> | ResultError<Err>;

export interface ResultOk<T> {
  readonly tag: "ok";
  readonly value: T;
}

export interface ResultError<Err> {
  readonly tag: "error";
  readonly error: Err;
}

export const Result = {
  of<T>(value: T): Result<T, any> {
    return { tag: "ok", value };
  },
  error<Err extends TalerErrorCode>(error: Err): ResultError<Err> {
    return { tag: "error", error };
  },
};
