import { Amounts } from "../amounts.js";
import { LimitOperationType } from "../types-taler-exchange.js";
import {
  AccountProperties,
  KycRule,
  LegitimizationRuleSet,
} from "../types-taler-kyc-aml.js";

/**
 * List of events triggered by TOPS
 */
export enum TOPS_AmlEventsName {
  ACCOUNT_OPENED = "ACCOUNT_OPENED",
  ACCOUNT_OPENED_HIGH_RISK = "ACCOUNT_OPENED_HIGH_RISK",
  ACCOUNT_OPENED_DOMESTIC_PEP = "ACCOUNT_OPENED_DOMESTIC_PEP",
  ACCOUNT_OPENED_FOREIGN_PEP = "ACCOUNT_OPENED_FOREIGN_PEP",
  ACCOUNT_OPENED_INT_ORG_PEP = "ACCOUNT_OPENED_INT_ORG_PEP",
  ACCOUNT_OPENED_HR_COUNTRY = "ACCOUNT_OPENED_HR_COUNTRY",

  ACCOUNT_CLOSED = "ACCOUNT_CLOSED",
  ACCOUNT_CLOSED_HIGH_RISK = "ACCOUNT_CLOSED_HIGH_RISK",
  ACCOUNT_CLOSED_DOMESTIC_PEP = "ACCOUNT_CLOSED_DOMESTIC_PEP",
  ACCOUNT_CLOSED_FOREIGN_PEP = "ACCOUNT_CLOSED_FOREIGN_PEP",
  ACCOUNT_CLOSED_INT_ORG_PEP = "ACCOUNT_CLOSED_INT_ORG_PEP",
  ACCOUNT_CLOSED_HR_COUNTRY = "ACCOUNT_CLOSED_HR_COUNTRY",

  ACCOUNT_MROS_REPORTED_SUSPICION_SIMPLE = "ACCOUNT_MROS_REPORTED_SUSPICION_SIMPLE",
  ACCOUNT_MROS_REPORTED_SUSPICION_SUBSTANTIATED = "ACCOUNT_MROS_REPORTED_SUSPICION_SUBSTANTIATED",
  ACCOUNT_INVESTIGATION_STARTED = "ACCOUNT_INVESTIGATION_STARTED",
  ACCOUNT_INVESTIGATION_COMPLETED = "ACCOUNT_INVESTIGATION_COMPLETED",

  TEST_EVENT_KEY_1 = "TEST_EVENT_KEY_1",
  TEST_EVENT_KEY_2 = "TEST_EVENT_KEY_2",
  TEST_EVENT_KEY_3 = "TEST_EVENT_KEY_3",
  TEST_EVENT_KEY_4 = "TEST_EVENT_KEY_4",
  TEST_EVENT_KEY_5 = "TEST_EVENT_KEY_5",
}

/**
 * List of events triggered by GLS
 */
export enum GLS_AmlEventsName {
  ACCOUNT_OPENED = "ACCOUNT_OPENED",
  ACCOUNT_CLOSED = "ACCOUNT_CLOSED",
}

export type EventMapInfo<T> = {
  [name in keyof T]: {
    /**
     * Based on the current properties and next properties,
     * the current account limits and new attribues of the account
     * calculate if the event should be triggered.
     *
     * return false if there is no enough information to decide.
     *
     * @param prevLimits current active decision limits, undefined if this account has no active decision yet
     * @param nextLimits limits of the decision to be made, undefined if not yet decided
     * @param prevState current active decision properties, undefined if this account has no active decision yet
     * @param nextState new properties of the account defined by the decision, undefined if not yet decided
     * @param newAttributes new information added by this decision
     * @returns
     */
    shouldBeTriggered: (
      prevLimits: KycRule[] | undefined,
      nextLimits: KycRule[] | undefined,
      prevState: AccountProperties | undefined,
      nextState: AccountProperties | undefined,
      newAttributes: Record<string, unknown>,
    ) => boolean;
  };
};

function isAllowToMakeDeposits(limits: KycRule[]) {
  const depositLimits = limits.filter(
    (r) => r.operation_type === LimitOperationType.deposit,
  );
  // no deposit limits
  if (!depositLimits.length) return true;
  const zero = depositLimits.find((d) => Amounts.isZero(d.threshold));
  // there is a rule that prohibit deposit
  if (zero) return false;
  // the cusomter can at least make some deposits
  return true;
}

export const AML_EVENTS_INFO: EventMapInfo<typeof TOPS_AmlEventsName> = {
  ACCOUNT_OPENED: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      //FIXME: implement the correct rule, this is for testing
      if (!nL) return false;
      return pL === undefined
        ? !isAllowToMakeDeposits(nL)
        : isAllowToMakeDeposits(pL) && !isAllowToMakeDeposits(nL);
    },
  },
  ACCOUNT_CLOSED: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_CLOSED_INT_ORG_PEP: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_OPENED_HIGH_RISK: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_CLOSED_HIGH_RISK: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_OPENED_DOMESTIC_PEP: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_CLOSED_DOMESTIC_PEP: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_OPENED_FOREIGN_PEP: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_CLOSED_FOREIGN_PEP: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_OPENED_HR_COUNTRY: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_CLOSED_HR_COUNTRY: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_INVESTIGATION_COMPLETED: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_INVESTIGATION_STARTED: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_MROS_REPORTED_SUSPICION_SIMPLE: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_MROS_REPORTED_SUSPICION_SUBSTANTIATED: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_OPENED_INT_ORG_PEP: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  TEST_EVENT_KEY_1: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  TEST_EVENT_KEY_2: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  TEST_EVENT_KEY_3: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  TEST_EVENT_KEY_4: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  TEST_EVENT_KEY_5: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
};

export const GLS_AML_EVENTS: EventMapInfo<typeof GLS_AmlEventsName> = {
  ACCOUNT_OPENED: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
  ACCOUNT_CLOSED: {
    shouldBeTriggered(pL, nL, pS, nS, attr) {
      return false;
    },
  },
};
