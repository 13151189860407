import { TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { RenderAddon } from "./fields/InputLine.js";
import { Addon } from "./FormProvider.js";

interface Props {
  label: TranslatedString;
  url: string;
  tooltip?: TranslatedString;
  help?: TranslatedString;
  before?: Addon;
  after?: Addon;
}

export function HtmlIframe({
  before,
  url,
  after,
  label,
  tooltip,
  help,
}: Props): VNode {
  return (
    <div class="sm:col-span-6">
      {before !== undefined && <RenderAddon addon={before} />}
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        class="underline cursor-pointer"
      >
        <p>{label}</p>
      </a>
      <iframe src={url} title={label} class="w-full"></iframe>
      {after !== undefined && <RenderAddon addon={after} />}
      {help && (
        <p class="mt-2 text-sm text-gray-500" id="email-description">
          {help}
        </p>
      )}
    </div>
  );
}
