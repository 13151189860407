/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  assertUnreachable,
  getMerchantAccountKycStatusSimplified,
  TalerMerchantApi,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";

export interface Props {
  status: TalerMerchantApi.MerchantAccountKycRedirectsResponse;
  // onGetInfo: (url: string, token: AccessToken) => void;
  onShowInstructions: (e: TalerMerchantApi.MerchantAccountKycRedirect) => void;
}

export function ListPage({
  status,
  // onGetInfo,
  onShowInstructions,
}: Props): VNode {
  const { i18n } = useTranslationContext();

  return (
    <section class="section is-main-section">
      <div class="card has-table">
        <header class="card-header">
          <p class="card-header-title">
            <span class="icon">
              <i class="mdi mdi-clock" />
            </span>
            <i18n.Translate>Account's KYC status</i18n.Translate>
          </p>

          <div class="card-header-icon" aria-label="more options" />
        </header>
        <div class="card-content">
          <div class="b-table has-pagination">
            <div class="table-wrapper has-mobile-cards">
              {status.kyc_data.length > 0 ? (
                <PendingTable
                  entries={status.kyc_data}
                  // onGetInfo={onGetInfo}
                  onShowInstructions={onShowInstructions}
                />
              ) : (
                <EmptyTable />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
interface PendingTableProps {
  entries: TalerMerchantApi.MerchantAccountKycRedirect[];
  // onGetInfo: (url: string, token: AccessToken) => void;
  onShowInstructions: (e: TalerMerchantApi.MerchantAccountKycRedirect) => void;
}

interface TimedOutTableProps {
  entries: TalerMerchantApi.ExchangeKycTimeout[];
}

function PendingTable({
  entries,
  onShowInstructions,
  // onGetInfo,
}: PendingTableProps): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="table-container">
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <i18n.Translate>Exchange</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Account</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Status</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Description</i18n.Translate>
            </th>
          </tr>
        </thead>
        <tbody>
          {entries.map((e, i) => {
            return (
              <tr
                key={i}
                onClick={() => onShowInstructions(e)}
                style={{ cursor: "pointer" }}
              >
                <td>{e.exchange_url}</td>
                <td>{e.payto_uri}</td>
                <td>
                  {(function (): VNode {
                    const st = getMerchantAccountKycStatusSimplified(e.status);
                    switch (st) {
                      case TalerMerchantApi.MerchantAccountKycStatusSimplified
                        .OK:
                        return <i18n.Translate>Ok</i18n.Translate>;
                      case TalerMerchantApi.MerchantAccountKycStatusSimplified
                        .ACTION_REQUIRED:
                        return <i18n.Translate>Action required</i18n.Translate>;
                      case TalerMerchantApi.MerchantAccountKycStatusSimplified
                        .WARNING:
                        return <i18n.Translate>Warning</i18n.Translate>;
                      case TalerMerchantApi.MerchantAccountKycStatusSimplified
                        .ERROR:
                        return <i18n.Translate>Error</i18n.Translate>;
                      default:
                        assertUnreachable(st);
                    }
                  })()}
                </td>
                <td>
                  {(function (): VNode {
                    switch (e.status) {
                      case TalerMerchantApi.MerchantAccountKycStatus
                        .KYC_WIRE_REQUIRED:
                        // action required
                        return (
                          <i18n.Translate>
                            KYC wire transfer required, click for details.
                          </i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus
                        .KYC_REQUIRED:
                        // action required
                        return (
                          <i18n.Translate>
                            KYC required, click here to proceed
                          </i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus
                        .AWAITING_AML_REVIEW:
                        // FIXME: can the account be used?
                        return (
                          <i18n.Translate>Awaiting AML review</i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus.READY:
                        return <i18n.Translate>Ready</i18n.Translate>;

                      case TalerMerchantApi.MerchantAccountKycStatus
                        .NO_EXCHANGE_KEY:
                        return (
                          <i18n.Translate>
                            Updating exchange keys...
                          </i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus
                        .EXCHANGE_INTERNAL_ERROR:
                        return (
                          <i18n.Translate>
                            Exchange internal error. Contact administrator or
                            check again later.
                          </i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus
                        .EXCHANGE_GATEWAY_TIMEOUT:
                        return (
                          <i18n.Translate>
                            Exchange timeout. Contact administrator or check
                            again later.
                          </i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus
                        .EXCHANGE_UNREACHABLE:
                        return (
                          <i18n.Translate>
                            Exchange unreachable. Contact administrator or check
                            again later.
                          </i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus
                        .KYC_WIRE_IMPOSSIBLE:
                        return (
                          <i18n.Translate>
                            Can't complete due to wire transfer incomptability.
                          </i18n.Translate>
                        );
                      case TalerMerchantApi.MerchantAccountKycStatus.LOGIC_BUG:
                        return (
                          <i18n.Translate>
                            Merchant internal error. Contact administrator or
                            check again later.
                          </i18n.Translate>
                        );

                      case TalerMerchantApi.MerchantAccountKycStatus
                        .EXCHANGE_STATUS_INVALID:
                        return (
                          <i18n.Translate>
                            Exchange response is invalid. Contact administrator
                            or check again later.
                          </i18n.Translate>
                        );

                      default:
                        assertUnreachable(e.status);
                    }
                  })()}
                </td>
              </tr>
            );
            // if (e.payto_kycauths === undefined) {
            //   const spa = new URL(`kyc-spa/${e.access_token}`, e.exchange_url)
            //     .href;
            // } else {
            //   const accounts = e.payto_kycauths;
            //   return (
            //     <tr key={i}>
            //       <td onClick={() => onShowInstructions(accounts, e.payto_uri)}>
            //         {e.exchange_url}
            //       </td>
            //       <td
            //         onClick={() => onShowInstructions(accounts, e.payto_uri)}
            //         style={{ cursor: "pointer" }}
            //       >
            //         {e.payto_uri}
            //       </td>
            //       <td onClick={() => onShowInstructions(accounts, e.payto_uri)}>
            //         {e.status}
            //         <i18n.Translate>
            //           The Payment Service Provider requires an account
            //           verification.
            //         </i18n.Translate>
            //       </td>
            //     </tr>
            //   );
            // }
          })}
        </tbody>
      </table>
    </div>
  );
}

function TimedOutTable({ entries }: TimedOutTableProps): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="table-container">
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <i18n.Translate>Exchange</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Code</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Http Status</i18n.Translate>
            </th>
          </tr>
        </thead>
        <tbody>
          {entries.map((e, i) => {
            return (
              <tr key={i}>
                <td>{e.exchange_url}</td>
                <td>{e.exchange_code}</td>
                <td>{e.exchange_http_status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function EmptyTable(): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="content has-text-grey has-text-centered">
      <p>
        <span class="icon is-large">
          <i class="mdi mdi-emoticon-happy mdi-48px" />
        </span>
      </p>
      <p>
        <i18n.Translate>No pending kyc verification!</i18n.Translate>
      </p>
    </div>
  );
}
