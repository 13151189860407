/*
  This file is part of GNU Taler
  Copyright (C) 2012-2025 Taler Systems SA

  GNU Taler is free software: you can redistribute it and/or modify it
  under the terms of the GNU Lesser General Public License as published
  by the Free Software Foundation, either version 3 of the License,
  or (at your option) any later version.

  GNU Taler is distributed in the hope that it will be useful, but
  WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
  Lesser General Public License for more details.

  You should have received a copy of the GNU Lesser General Public License
  along with this program.  If not, see <http://www.gnu.org/licenses/>.

  SPDX-License-Identifier: LGPL3.0-or-later

  Note: the LGPL does not apply to all components of GNU Taler,
  but it does apply to this file.
 */

/**
 * Imports.
 */

export const TalerFormAttributes = {
    /**
     * Description: Suppress flagging this account when it creates a hit on a sanctions list, this is a false-positive.
     *
     * GANA Type: Boolean
     */
    SANCTION_LIST_SUPPRESS: "SANCTION_LIST_SUPPRESS" as const,
    /**
     * Description: When the form was completed.
     *
     * GANA Type: AbsoluteDateTime
     */
    FORM_FILLING_DATE: "FORM_FILLING_DATE" as const,
    /**
     * Description: Customer system ID required to correlate different AML forms.
     *
     * GANA Type: String
     */
    CUSTOMER_ID: "CUSTOMER_ID" as const,
    /**
     * Description: Contracing partner signature,
     *
     * GANA Type: AbsoluteDateTime
     */
    SIGN_DATE: "SIGN_DATE" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    SIGNATURE: "SIGNATURE" as const,
    /**
     * Description: 
     *
     * GANA Type: 'NATURAL_PERSON' | 'LEGAL_ENTITY'
     */
    CUSTOMER_TYPE: "CUSTOMER_TYPE" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    CUSTOMER_NATURAL_FULL_NAME: "CUSTOMER_NATURAL_FULL_NAME" as const,
    /**
     * Description: If the customer is a natural person.
     *
     * GANA Type: Phone
     */
    CUSTOMER_NATURAL_PHONE: "CUSTOMER_NATURAL_PHONE" as const,
    /**
     * Description: If the customer is a natural person.
     *
     * GANA Type: ISO Date
     */
    DATE_OF_BIRTH: "DATE_OF_BIRTH" as const,
    /**
     * Description: If the customer is a natural person.
     *
     * GANA Type: File
     */
    PERSONAL_IDENTIFICATION_DOCUMENT_COPY: "PERSONAL_IDENTIFICATION_DOCUMENT_COPY" as const,
    /**
     * Description: Is the customer a sole proprietor?
     *
     * GANA Type: Boolean
     */
    CUSTOMER_IS_SOLE_PROPRIETOR: "CUSTOMER_IS_SOLE_PROPRIETOR" as const,
    /**
     * Description: If the customer is a natural person.
     *
     * GANA Type: String
     */
    COMPANY_NAME: "COMPANY_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    REGISTERED_OFFICE_ADDRESS: "REGISTERED_OFFICE_ADDRESS" as const,
    /**
     * Description: If the customer is a legal person.
     *
     * GANA Type: String
     */
    LEGAL_ENTITY_IDENTIFICATION_DOCUMENT_COPY: "LEGAL_ENTITY_IDENTIFICATION_DOCUMENT_COPY" as const,
    /**
     * Description: If the customer is a legal entity.
     *
     * GANA Type: String
     */
    CUSTOMER_ENTITY_COMPANY_NAME: "CUSTOMER_ENTITY_COMPANY_NAME" as const,
    /**
     * Description: If the customer is a legal entity.
     *
     * GANA Type: BusinessAddress
     */
    CUSTOMER_ENTITY_ADDRESS: "CUSTOMER_ENTITY_ADDRESS" as const,
    /**
     * Description: Full name of the contact person.
     *
     * GANA Type: String
     */
    CONTACT_PERSON_NAME: "CONTACT_PERSON_NAME" as const,
    /**
     * Description: If the customer is a legal entity.
     *
     * GANA Type: Phone
     */
    CUSTOMER_ENTITY_PHONE: "CUSTOMER_ENTITY_PHONE" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    DESCRIPTION: "DESCRIPTION" as const,
    /**
     * Description: 
     *
     * GANA Type: DataUri
     */
    FILE: "FILE" as const,
    /**
     * Description: List of founder with the fields below.
     *
     * GANA Type: Form<VQF_902_1_founder>[]
     */
    ESTABLISHER_LIST: "ESTABLISHER_LIST" as const,
    /**
     * Description: Full name of a natural person.
     *
     * GANA Type: String
     */
    FULL_NAME: "FULL_NAME" as const,
    /**
     * Description: Domicile address. A domicile is a place where you live, an address is just a place where you receive mail. A residential address is somewhere you live but not necessarily your primary place.
     *
     * GANA Type: ResidentialAddress
     */
    DOMICILE_ADDRESS: "DOMICILE_ADDRESS" as const,
    /**
     * Description: Nationality, expressed as a country code.
     *
     * GANA Type: CountryCode
     */
    NATIONALITY: "NATIONALITY" as const,
    /**
     * Description: Signatory of representation (single, collective two, ...)
     *
     * GANA Type: String
     */
    SIGNING_AUTHORITY_TYPE: "SIGNING_AUTHORITY_TYPE" as const,
    /**
     * Description: Signatory of representation (other type)
     *
     * GANA Type: String
     */
    SIGNING_AUTHORITY_TYPE_OTHER: "SIGNING_AUTHORITY_TYPE_OTHER" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    FOUNDER_NATIONAL_ID: "FOUNDER_NATIONAL_ID" as const,
    /**
     * Description: 
     *
     * GANA Type: File
     */
    FOUNDER_NATIONAL_COPY: "FOUNDER_NATIONAL_COPY" as const,
    /**
     * Description: 
     *
     * GANA Type: 'CR' | 'MANDATE' | 'OTHER' 
     */
    SIGNING_AUTHORITY_EVIDENCE: "SIGNING_AUTHORITY_EVIDENCE" as const,
    /**
     * Description: 
     *
     * GANA Type: String 
     */
    SIGNING_AUTHORITY_EVIDENCE_OTHER: "SIGNING_AUTHORITY_EVIDENCE_OTHER" as const,
    /**
     * Description: 
     *
     * GANA Type: File 
     */
    SIGNING_AUTHORITY_EVIDENCE_DOCUMENT_COPY: "SIGNING_AUTHORITY_EVIDENCE_DOCUMENT_COPY" as const,
    /**
     * Description: Conclusion of the conract
     *
     * GANA Type: AbsoluteDate
     */
    ACCEPTANCE_DATE: "ACCEPTANCE_DATE" as const,
    /**
     * Description: 
     *
     * GANA Type: 'FACE_TO_FACE' | 'AUTHENTICATED_COPY' | 'RESIDENTIAL_ADDRESS_VALIDATED'
     */
    ACCEPTANCE_METHOD: "ACCEPTANCE_METHOD" as const,
    /**
     * Description: 
     *
     * GANA Type: LangCode
     */
    CORRESPONDENCE_LANGUAGE: "CORRESPONDENCE_LANGUAGE" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    ACCEPTANCE_FURTHER_INFO: "ACCEPTANCE_FURTHER_INFO" as const,
    /**
     * Description: Customer type under the VQF classification. Establishment of the beneficial owner of the assets and/or controlling person
     *
     * GANA Type: 'NATURAL' | 'OPERATIONAL' | 'FOUNDATION' | 'TRUST' | 'LIFE_INSURANCE' | 'OTHER'
     */
    CUSTOMER_TYPE_VQF: "CUSTOMER_TYPE_VQF" as const,
    /**
     * Description: Verification whether the customer, beneficial owners of the assets, controlling persons, authorised representatives or other involved persons are listed on an embargo-/terrorism list (date of verification/result)
     *
     * GANA Type: Paragraph
     */
    EMBARGO_TERRORISM_INFO: "EMBARGO_TERRORISM_INFO" as const,
    /**
     * Description: Verification date.
     *
     * GANA Type: Date
     */
    EMBARGO_TERRORISM_CHECK_DATE: "EMBARGO_TERRORISM_CHECK_DATE" as const,
    /**
     * Description: Verification date.
     *
     * GANA Type: 'NOT_LISTED' | 'LISTED'
     */
    EMBARGO_TERRORISM_CHECK_RESULT: "EMBARGO_TERRORISM_CHECK_RESULT" as const,
    /**
     * Description: 
     *
     * GANA Type: 'MONEY_EXCHANGE' | 'MONEY_ASSET_TRANSFER' | 'OTHER' 
     */
    RELATIONSHIP_TYPE: "RELATIONSHIP_TYPE" as const,
    /**
     * Description: 
     *
     * GANA Type: String 
     */
    RELATIONSHIP_TYPE_OTHER: "RELATIONSHIP_TYPE_OTHER" as const,
    /**
     * Description: Purpose of service requested
     *
     * GANA Type: Paragraph
     */
    RELATIONSHIP_PURPOSE: "RELATIONSHIP_PURPOSE" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    ENCLOSURE_CUSTOMER_DOCUMENTS: "ENCLOSURE_CUSTOMER_DOCUMENTS" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    ENCLOSURE_IDENTIFICATION_DOCUMENTS: "ENCLOSURE_IDENTIFICATION_DOCUMENTS" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    ENCLOSURE_BENEFICIAL_OWNER: "ENCLOSURE_BENEFICIAL_OWNER" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    ENCLOSURE_CUSTOMER_PROFILE: "ENCLOSURE_CUSTOMER_PROFILE" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    ENCLOSURE_RISK_PROFILE: "ENCLOSURE_RISK_PROFILE" as const,
    /**
     * Description: True if the person is a PEP defined by 'Art 7 lit. g numeral 1'
     *
     * GANA Type: Boolean
     */
    PEP_FOREIGN: "PEP_FOREIGN" as const,
    /**
     * Description: True if the person is a PEP defined by 'Art 7 lit. g numeral 2'
     *
     * GANA Type: Boolean
     */
    PEP_DOMESTIC: "PEP_DOMESTIC" as const,
    /**
     * Description: True if the person is a PEP defined by 'Art 7 lit. g numeral 3'
     *
     * GANA Type: Boolean
     */
    PEP_INTERNATIONAL_ORGANIZATION: "PEP_INTERNATIONAL_ORGANIZATION" as const,
    /**
     * Description: The decision of the Senior executive body on the acceptance of a business relationsip was obtained on ___
     *
     * GANA Type: AbsoluteDateTime
     */
    PEP_ACCEPTANCE_DATE: "PEP_ACCEPTANCE_DATE" as const,
    /**
     * Description: True if the person is in a country for which FATF requires incresed dilegence.
     *
     * GANA Type: Boolean
     */
    HIGH_RISK_COUNTRY: "HIGH_RISK_COUNTRY" as const,
    /**
     * Description: The decision of the Senior executive body on the acceptance of a business relationsip was obtained on ___
     *
     * GANA Type: AbsoluteDateTime
     */
    HIGH_RISK_ACCEPTANCE_DATE: "HIGH_RISK_ACCEPTANCE_DATE" as const,
    /**
     * Description: 
     *
     * GANA Type: 'NATIONALITY_CUSTOMER' | 'NATIONALITY_OWNER' | 'DOMICILE_CUSTOMER' | 'DOMICILE_OWNER' | 'DOMICILE_CONTROLLING'
     */
    COUNTRY_RISK_NATIONALITY_TYPE: "COUNTRY_RISK_NATIONALITY_TYPE" as const,
    /**
     * Description: Based on 902.4.1 country list
     *
     * GANA Type: 'LOW' | 'MEDIUM' | 'HIGH'
     */
    COUNTRY_RISK_NATIONALITY_LEVEL: "COUNTRY_RISK_NATIONALITY_LEVEL" as const,
    /**
     * Description: 
     *
     * GANA Type: 'CUSTOMER' | 'OWNER'
     */
    COUNTRY_RISK_BUSINESS_TYPE: "COUNTRY_RISK_BUSINESS_TYPE" as const,
    /**
     * Description: Based on 902.4.1 country list
     *
     * GANA Type: 'LOW' | 'MEDIUM' | 'HIGH'
     */
    COUNTRY_RISK_BUSINESS_LEVEL: "COUNTRY_RISK_BUSINESS_LEVEL" as const,
    /**
     * Description: Based on 902.4.1 country list
     *
     * GANA Type: 'LOW' | 'MEDIUM' | 'HIGH'
     */
    COUNTRY_RISK_PAYMENTS_LEVEL: "COUNTRY_RISK_PAYMENTS_LEVEL" as const,
    /**
     * Description: 
     *
     * GANA Type: 'CUSTOMER' | 'OWNER'
     */
    INDUSTRY_RISK_TYPE: "INDUSTRY_RISK_TYPE" as const,
    /**
     * Description: 
     *
     * GANA Type: 'TRANSPARENT' | 'HIGH_CASH_TRANSACTION' | 'NOT_WELL_KNOWN' | 'HIGH_RISK_TRADE' | 'UNKNOWN_INDUSTRY'
     */
    INDUSTRY_RISK_LEVEL: "INDUSTRY_RISK_LEVEL" as const,
    /**
     * Description: Based on 902.4.1 country list
     *
     * GANA Type: 'LOW' | 'MEDIUM' | 'HIGH'
     */
    CONTACT_RISK_LEVEL: "CONTACT_RISK_LEVEL" as const,
    /**
     * Description: 
     *
     * GANA Type: 'EASY' | 'SOPHISTICATED' | 'OFFSHORE' | 'COMPLEX_STRUCTURE' | 'LARGE_NUMBER_OF_ACCOUNTS' | 'COMPLEX_SERVICE' | 'FREQ_TRANS_WITH_HIGH_RISK'
     */
    PRODUCT_RISK_LEVEL: "PRODUCT_RISK_LEVEL" as const,
    /**
     * Description: 
     *
     * GANA Type: 'HIGH_RISK' | 'NO_HIGH_RISK'
     */
    RISK_CLASSIFICATION_LEVEL: "RISK_CLASSIFICATION_LEVEL" as const,
    /**
     * Description: Justification for differing risk assessment
     *
     * GANA Type: Paragraph
     */
    RISK_RATIONALY: "RISK_RATIONALY" as const,
    /**
     * Description: The decision of the Senior executive body on the acceptance of a business relationsip was obtained on ___
     *
     * GANA Type: AbsoluteDateTime
     */
    RISK_ACCEPTANCE_DATE: "RISK_ACCEPTANCE_DATE" as const,
    /**
     * Description: Profession, business activities, etc. (former, current, potentially planned)
     *
     * GANA Type: String
     */
    BIZREL_PROFESSION: "BIZREL_PROFESSION" as const,
    /**
     * Description: Income and assets, liabilities (estimated)
     *
     * GANA Type: String
     */
    BIZREL_INCOME: "BIZREL_INCOME" as const,
    /**
     * Description: Does the customer have assets that will be deposited?
     *
     * GANA Type: Boolean
     */
    BIZREL_HAVE_ASSETS: "BIZREL_HAVE_ASSETS" as const,
    /**
     * Description: Nature of the involved assets.
     *
     * GANA Type: String
     */
    BIZREL_ORIGIN_NATURE: "BIZREL_ORIGIN_NATURE" as const,
    /**
     * Description: Currency of the involved assets.
     *
     * GANA Type: Amount
     */
    BIZREL_ORIGIN_CURRENCY: "BIZREL_ORIGIN_CURRENCY" as const,
    /**
     * Description: Amount of the involved assets.
     *
     * GANA Type: Amount
     */
    BIZREL_ORIGIN_AMOUNT: "BIZREL_ORIGIN_AMOUNT" as const,
    /**
     * Description: 
     *
     * GANA Type: 'SAVINGS' | 'OWN_BUSINESS' | 'INHERITANCE' | 'OTHER'
     */
    BIZREL_ORIGIN_CATEGORY: "BIZREL_ORIGIN_CATEGORY" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    BIZREL_ORIGIN_CATEGORY_OTHER: "BIZREL_ORIGIN_CATEGORY_OTHER" as const,
    /**
     * Description: Detail description of the origings
     *
     * GANA Type: Paragraph
     */
    BIZREL_ORIGIN_DETAIL: "BIZREL_ORIGIN_DETAIL" as const,
    /**
     * Description: Purpose of the business relationship.
     *
     * GANA Type: String
     */
    BIZREL_PURPOSE: "BIZREL_PURPOSE" as const,
    /**
     * Description: Information on the planned development of the business relationship and the assets.
     *
     * GANA Type: String
     */
    BIZREL_DEVELOPMENT: "BIZREL_DEVELOPMENT" as const,
    /**
     * Description: In the case of cash or money and asset transfer transacction with regular customer
     *
     * GANA Type: String
     */
    BIZREL_FINANCIAL_VOLUME: "BIZREL_FINANCIAL_VOLUME" as const,
    /**
     * Description: In the case of cash or money and asset transfer transacction with regular customer
     *
     * GANA Type: String
     */
    BIZREL_FINANCIAL_BENEFICIARIES_FULL_NAME: "BIZREL_FINANCIAL_BENEFICIARIES_FULL_NAME" as const,
    /**
     * Description: In the case of cash or money and asset transfer transacction with regular customer
     *
     * GANA Type: BusinessAddress
     */
    BIZREL_FINANCIAL_BENEFICIARIES_ADDRESS: "BIZREL_FINANCIAL_BENEFICIARIES_ADDRESS" as const,
    /**
     * Description: In the case of cash or money and asset transfer transacction with regular customer
     *
     * GANA Type: String
     */
    BIZREL_FINANCIAL_BENEFICIARIES_BANK_ACCOUNT: "BIZREL_FINANCIAL_BENEFICIARIES_BANK_ACCOUNT" as const,
    /**
     * Description: Relation of the customer to the beneficial owner, controlling persons, authorised signatories and other persons involved in the business relationship.
     *
     * GANA Type: String
     */
    BIZREL_THIRDPARTY_RELATIONSHIP: "BIZREL_THIRDPARTY_RELATIONSHIP" as const,
    /**
     * Description: Relation to other AMLA-files.
     *
     * GANA Type: String
     */
    BIZREL_THIRDPARTY_AMLA_FILES: "BIZREL_THIRDPARTY_AMLA_FILES" as const,
    /**
     * Description: Introducer / agents / references.
     *
     * GANA Type: String
     */
    BIZREL_THIRDPARTY_REFERENCES: "BIZREL_THIRDPARTY_REFERENCES" as const,
    /**
     * Description: Other relevant information.
     *
     * GANA Type: String
     */
    BIZREL_FURTHER_INFO: "BIZREL_FURTHER_INFO" as const,
    /**
     * Description: 
     *
     * GANA Type: Paragraph
     */
    IDENTITY_CONTRACTING_PARTNER: "IDENTITY_CONTRACTING_PARTNER" as const,
    /**
     * Description: 
     *
     * GANA Type: File
     */
    ATTACHMENT_SIGNED_DOCUMENT: "ATTACHMENT_SIGNED_DOCUMENT" as const,
    /**
     * Description: The beneficial owners of the assets involved in the business relationship.
     *
     * GANA Type: Form<VQF_902_9_identity>[]
     */
    IDENTITY_LIST: "IDENTITY_LIST" as const,
    /**
     * Description: Party that is filling out the form.
     *
     * GANA Type: 'AML_OFFICER' | 'CUSTOMER'
     */
    SUBMITTED_BY: "SUBMITTED_BY" as const,
    /**
     * Description: 
     *
     * GANA Type: '25_MORE_RIGHTS' | 'OTHER_WAY' | 'DIRECTOR'
     */
    CONTROL_REASON: "CONTROL_REASON" as const,
    /**
     * Description: Is a third person the beneficial owner of the assets?
     *
     * GANA Type: Boolean
     */
    CONTROLLING_ENTITY_THIRD_PERSON: "CONTROLLING_ENTITY_THIRD_PERSON" as const,
    /**
     * Description: 
     *
     * GANA Type: Paragraph
     */
    FOUNDATION_CONTRACTING_PARTNER: "FOUNDATION_CONTRACTING_PARTNER" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    FOUNDATION_KNOWN_AS: "FOUNDATION_KNOWN_AS" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    FOUNDATION_NAME: "FOUNDATION_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    FOUNDATION_DISCRETIONARY: "FOUNDATION_DISCRETIONARY" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    FOUNDATION_REVOCABLE: "FOUNDATION_REVOCABLE" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_12_founder>[]
     */
    FOUNDATION_FOUNDER_LIST: "FOUNDATION_FOUNDER_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    FOUNDATION_FOUNDER_FULL_NAME: "FOUNDATION_FOUNDER_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    FOUNDATION_FOUNDER_DOMICILE: "FOUNDATION_FOUNDER_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_FOUNDER_COUNTRY: "FOUNDATION_FOUNDER_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    FOUNDATION_FOUNDER_BIRTHDATE: "FOUNDATION_FOUNDER_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_FOUNDER_NATIONALITY: "FOUNDATION_FOUNDER_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    FOUNDATION_FOUNDER_DEATHDATE: "FOUNDATION_FOUNDER_DEATHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    FOUNDATION_FOUNDER_RIGHT_TO_REVOKE: "FOUNDATION_FOUNDER_RIGHT_TO_REVOKE" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_12_pre>[]
     */
    FOUNDATION_PRE_LIST: "FOUNDATION_PRE_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    FOUNDATION_PRE_FULL_NAME: "FOUNDATION_PRE_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    FOUNDATION_PRE_DOMICILE: "FOUNDATION_PRE_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_PRE_COUNTRY: "FOUNDATION_PRE_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    FOUNDATION_PRE_BIRTHDATE: "FOUNDATION_PRE_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_PRE_NATIONALITY: "FOUNDATION_PRE_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    FOUNDATION_PRE_DEATHDATE: "FOUNDATION_PRE_DEATHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_12_beneficiary>[]
     */
    FOUNDATION_BENEFICIARY_LIST: "FOUNDATION_BENEFICIARY_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    FOUNDATION_BENEFICIARY_FULL_NAME: "FOUNDATION_BENEFICIARY_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    FOUNDATION_BENEFICIARY_DOMICILE: "FOUNDATION_BENEFICIARY_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_BENEFICIARY_COUNTRY: "FOUNDATION_BENEFICIARY_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    FOUNDATION_BENEFICIARY_BIRTHDATE: "FOUNDATION_BENEFICIARY_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_BENEFICIARY_NATIONALITY: "FOUNDATION_BENEFICIARY_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    FOUNDATION_BENEFICIARY_RIGHT_TO_CLAIM: "FOUNDATION_BENEFICIARY_RIGHT_TO_CLAIM" as const,
    /**
     * Description: 
     *
     * GANA Type: Paragraph
     */
    FOUNDATION_BENEFICIARY_ADDITION: "FOUNDATION_BENEFICIARY_ADDITION" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_12_representative>[]
     */
    FOUNDATION_REPRESENTATIVE_LIST: "FOUNDATION_REPRESENTATIVE_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    FOUNDATION_REPRESENTATIVE_FULL_NAME: "FOUNDATION_REPRESENTATIVE_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    FOUNDATION_REPRESENTATIVE_DOMICILE: "FOUNDATION_REPRESENTATIVE_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_REPRESENTATIVE_COUNTRY: "FOUNDATION_REPRESENTATIVE_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    FOUNDATION_REPRESENTATIVE_BIRTHDATE: "FOUNDATION_REPRESENTATIVE_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    FOUNDATION_REPRESENTATIVE_NATIONALITY: "FOUNDATION_REPRESENTATIVE_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    FOUNDATION_REPRESENTATIVE_RIGHT_TO_REVOKE: "FOUNDATION_REPRESENTATIVE_RIGHT_TO_REVOKE" as const,
    /**
     * Description: 
     *
     * GANA Type: Paragraph
     */
    TRUST_CONTRACTING_PARTNER: "TRUST_CONTRACTING_PARTNER" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    TRUST_KNOWN_AS: "TRUST_KNOWN_AS" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    TRUST_NAME: "TRUST_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    TRUST_DISCRETIONARY: "TRUST_DISCRETIONARY" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    TRUST_REVOCABLE: "TRUST_REVOCABLE" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_13_settlor>[]
     */
    TRUST_SETTLOR_LIST: "TRUST_SETTLOR_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    TRUST_SETTLOR_FULL_NAME: "TRUST_SETTLOR_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    TRUST_SETTLOR_DOMICILE: "TRUST_SETTLOR_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode 
     */
    TRUST_SETTLOR_COUNTRY: "TRUST_SETTLOR_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    TRUST_SETTLOR_BIRTHDATE: "TRUST_SETTLOR_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_SETTLOR_NATIONALITY: "TRUST_SETTLOR_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    TRUST_SETTLOR_DEATHDATE: "TRUST_SETTLOR_DEATHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    TRUST_SETTLOR_RIGHT_TO_REVOKE: "TRUST_SETTLOR_RIGHT_TO_REVOKE" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_13_pre>[]
     */
    TRUST_PRE_LIST: "TRUST_PRE_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    TRUST_PRE_FULL_NAME: "TRUST_PRE_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    TRUST_PRE_DOMICILE: "TRUST_PRE_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_PRE_COUNTRY: "TRUST_PRE_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    TRUST_PRE_BIRTHDATE: "TRUST_PRE_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_PRE_NATIONALITY: "TRUST_PRE_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    TRUST_PRE_DEATHDATE: "TRUST_PRE_DEATHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_13_beneficiary>[]
     */
    TRUST_BENEFICIARY_LIST: "TRUST_BENEFICIARY_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    TRUST_BENEFICIARY_FULL_NAME: "TRUST_BENEFICIARY_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    TRUST_BENEFICIARY_DOMICILE: "TRUST_BENEFICIARY_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_BENEFICIARY_COUNTRY: "TRUST_BENEFICIARY_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    TRUST_BENEFICIARY_BIRTHDATE: "TRUST_BENEFICIARY_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_BENEFICIARY_NATIONALITY: "TRUST_BENEFICIARY_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    TRUST_BENEFICIARY_RIGHT_TO_CLAIM: "TRUST_BENEFICIARY_RIGHT_TO_CLAIM" as const,
    /**
     * Description: 
     *
     * GANA Type: Paragraph
     */
    TRUST_BENEFICIARY_ADDITION: "TRUST_BENEFICIARY_ADDITION" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_13_protector>[]
     */
    TRUST_PROTECTOR_LIST: "TRUST_PROTECTOR_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    TRUST_PROTECTOR_FULL_NAME: "TRUST_PROTECTOR_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    TRUST_PROTECTOR_DOMICILE: "TRUST_PROTECTOR_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_PROTECTOR_COUNTRY: "TRUST_PROTECTOR_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    TRUST_PROTECTOR_BIRTHDATE: "TRUST_PROTECTOR_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_PROTECTOR_NATIONALITY: "TRUST_PROTECTOR_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    TRUST_PROTECTOR_RIGHT_TO_REVOKE: "TRUST_PROTECTOR_RIGHT_TO_REVOKE" as const,
    /**
     * Description: 
     *
     * GANA Type: Form<VQF_902_13_further>[]
     */
    TRUST_FURTHER_LIST: "TRUST_FURTHER_LIST" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    TRUST_FURTHER_FULL_NAME: "TRUST_FURTHER_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    TRUST_FURTHER_DOMICILE: "TRUST_FURTHER_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_FURTHER_COUNTRY: "TRUST_FURTHER_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    TRUST_FURTHER_BIRTHDATE: "TRUST_FURTHER_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    TRUST_FURTHER_NATIONALITY: "TRUST_FURTHER_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: Boolean
     */
    TRUST_FURTHER_RIGHT_TO_REVOKE: "TRUST_FURTHER_RIGHT_TO_REVOKE" as const,
    /**
     * Description: Description of the circumstances/transactions, which triggered the special clarifications
     *
     * GANA Type: String
     */
    INCRISK_REASON: "INCRISK_REASON" as const,
    /**
     * Description: 
     *
     * GANA Type: 'GATHERING' | 'CONSULTATION' | 'ENQUIRIES' | 'OTHER'
     */
    INCRISK_MEANS: "INCRISK_MEANS" as const,
    /**
     * Description: 
     *
     * GANA Type: String 
     */
    INCRISK_MEANS_OTHER: "INCRISK_MEANS_OTHER" as const,
    /**
     * Description: 
     *
     * GANA Type: Paragraph
     */
    INCRISK_SUMMARY: "INCRISK_SUMMARY" as const,
    /**
     * Description: Gathered or consulted documents
     *
     * GANA Type: Paragraph
     */
    INCRISK_DOCUMENTS: "INCRISK_DOCUMENTS" as const,
    /**
     * Description: 
     *
     * GANA Type: 'NO_SUSPICION' | 'REASONABLE_SUSPICION' | 'SIMPLE_SUSPICION' | 'OTHER'
     */
    INCRISK_RESULT: "INCRISK_RESULT" as const,
    /**
     * Description: 
     *
     * GANA Type: String 
     */
    INCRISK_RESULT_OTHER: "INCRISK_RESULT_OTHER" as const,
    /**
     * Description: 
     *
     * GANA Type: Paragraph
     */
    INSURANCE_CONTRACTING_PARTNER: "INSURANCE_CONTRACTING_PARTNER" as const,
    /**
     * Description: Name or number of the contractual relationship between the contracting party and the financial intermediary
     *
     * GANA Type: String
     */
    INSURANCE_RELATIONSHIP_NAME: "INSURANCE_RELATIONSHIP_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    INSURANCE_RELATIONSHIP_POLICY: "INSURANCE_RELATIONSHIP_POLICY" as const,
    /**
     * Description: The beneficial owners of the assets involved in the business relationship.
     *
     * GANA Type: String
     */
    INSURANCE_HOLDER_FULL_NAME: "INSURANCE_HOLDER_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    INSURANCE_HOLDER_BIRTHDATE: "INSURANCE_HOLDER_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    INSURANCE_HOLDER_NATIONALITY: "INSURANCE_HOLDER_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    INSURANCE_HOLDER_DOMICILE: "INSURANCE_HOLDER_DOMICILE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    INSURANCE_HOLDER_COUNTRY: "INSURANCE_HOLDER_COUNTRY" as const,
    /**
     * Description: The beneficial owners of the assets involved in the business relationship.
     *
     * GANA Type: String
     */
    INSURANCE_PAYER_FULL_NAME: "INSURANCE_PAYER_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    INSURANCE_PAYER_BIRTHDATE: "INSURANCE_PAYER_BIRTHDATE" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    INSURANCE_PAYER_NATIONALITY: "INSURANCE_PAYER_NATIONALITY" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    INSURANCE_PAYER_COUNTRY: "INSURANCE_PAYER_COUNTRY" as const,
    /**
     * Description: 
     *
     * GANA Type: ResidentialAddress
     */
    INSURANCE_PAYER_DOMICILE: "INSURANCE_PAYER_DOMICILE" as const,
    /**
     * Description: Full legal name of an individual as in the national identity card.
     *
     * GANA Type: String
     */
    PERSON_FULL_NAME: "PERSON_FULL_NAME" as const,
    /**
     * Description: Last name of an individual as in the national identity card.
     *
     * GANA Type: String
     */
    PERSON_LAST_NAME: "PERSON_LAST_NAME" as const,
    /**
     * Description: Identification number or string of national identity card.
     *
     * GANA Type: String
     */
    PERSON_NATIONAL_ID: "PERSON_NATIONAL_ID" as const,
    /**
     * Description: Date of birth of an individual. Format is YYYY-MM-DD.
     *
     * GANA Type: AbsoluteDate
     */
    PERSON_DATE_OF_BIRTH: "PERSON_DATE_OF_BIRTH" as const,
    /**
     * Description: Scan of a recognized national identity card of an individual.
     *
     * GANA Type: File
     */
    PERSON_NATIONAL_ID_SCAN: "PERSON_NATIONAL_ID_SCAN" as const,
    /**
     * Description: Nationality of an individual. Format is 2-letter ISO country-code.
     *
     * GANA Type: CountryCode
     */
    PERSON_NATIONALITY: "PERSON_NATIONALITY" as const,
    /**
     * Description: Name of the company or business.
     *
     * GANA Type: String
     */
    BUSINESS_DISPLAY_NAME: "BUSINESS_DISPLAY_NAME" as const,
    /**
     * Description: Type of company form or business (limited liability company, general partnership, limited partnership, corporations, etc... ).
     *
     * GANA Type: String 
     */
    BUSINESS_TYPE: "BUSINESS_TYPE" as const,
    /**
     * Description: Registration id on legal entity of the company or business.
     *
     * GANA Type: String
     */
    BUSINESS_REGISTRATION_ID: "BUSINESS_REGISTRATION_ID" as const,
    /**
     * Description: City or location where the company or business is registered.
     *
     * GANA Type: String
     */
    BUSINESS_LEGAL_JURISDICTION: "BUSINESS_LEGAL_JURISDICTION" as const,
    /**
     * Description: Registration founding date of the company or business.
     *
     * GANA Type: AbsoluteDate
     */
    BUSINESS_REGISTRATION_DATE: "BUSINESS_REGISTRATION_DATE" as const,
    /**
     * Description: True if the company or business is a non-profit.
     *
     * GANA Type: Boolean
     */
    BUSINESS_IS_NON_PROFIT: "BUSINESS_IS_NON_PROFIT" as const,
    /**
     * Description: Industry in which the company or business mainly operate.
     *
     * GANA Type: String
     */
    BUSINESS_INDUSTRY: "BUSINESS_INDUSTRY" as const,
    /**
     * Description: List of natural persons that are legal representatives or shareholders.
     *
     * GANA Type: GLS_BusinessRepresentative[]
     */
    BUSINESS_LEGAL_REPRESENTATIVES: "BUSINESS_LEGAL_REPRESENTATIVES" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    GLS_REPRESENTATIVE_FULL_NAME: "GLS_REPRESENTATIVE_FULL_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    GLS_REPRESENTATIVE_LAST_NAME: "GLS_REPRESENTATIVE_LAST_NAME" as const,
    /**
     * Description: 
     *
     * GANA Type: String
     */
    GLS_REPRESENTATIVE_NATIONAL_ID: "GLS_REPRESENTATIVE_NATIONAL_ID" as const,
    /**
     * Description: 
     *
     * GANA Type: AbsoluteDate
     */
    GLS_REPRESENTATIVE_DATE_OF_BIRTH: "GLS_REPRESENTATIVE_DATE_OF_BIRTH" as const,
    /**
     * Description: 
     *
     * GANA Type: File
     */
    GLS_REPRESENTATIVE_NATIONAL_ID_SCAN: "GLS_REPRESENTATIVE_NATIONAL_ID_SCAN" as const,
    /**
     * Description: 
     *
     * GANA Type: CountryCode
     */
    GLS_REPRESENTATIVE_NATIONALITY: "GLS_REPRESENTATIVE_NATIONALITY" as const,
    /**
     * Description: DNS domain name owned by the individual or business.
     *
     * GANA Type: Hostname
     */
    CONTACT_DNS_DOMAIN: "CONTACT_DNS_DOMAIN" as const,
    /**
     * Description: Web site owned by the individual or business.
     *
     * GANA Type: HttpHostnamePath
     */
    CONTACT_WEB_DOMAIN: "CONTACT_WEB_DOMAIN" as const,
    /**
     * Description: E-mail address to contact the individual or business. Can be validated via E-mail with TAN.
     *
     * GANA Type: Email
     */
    CONTACT_EMAIL: "CONTACT_EMAIL" as const,
    /**
     * Description: Phone number to contact the individual or business. Can be validated via SMS-TAN or phone call.
     *
     * GANA Type: Phone
     */
    CONTACT_PHONE: "CONTACT_PHONE" as const,
    /**
     * Description: Country where the individual or business resides. Format is 2-letter ISO country-code.
     *
     * GANA Type: CountryCode
     */
    ADDRESS_COUNTRY: "ADDRESS_COUNTRY" as const,
    /**
     * Description: Street address name of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_STREET_NAME: "ADDRESS_STREET_NAME" as const,
    /**
     * Description: Street address number of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_STREET_NUMBER: "ADDRESS_STREET_NUMBER" as const,
    /**
     * Description: Additional address information of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_LINES: "ADDRESS_LINES" as const,
    /**
     * Description: Building name of the of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_BUILDING_NAME: "ADDRESS_BUILDING_NAME" as const,
    /**
     * Description: Building number of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_BUILDING_NUMBER: "ADDRESS_BUILDING_NUMBER" as const,
    /**
     * Description: Postal code of the city where the individual or business resides.
     *
     * GANA Type: String
     */
    ADDRESS_ZIPCODE: "ADDRESS_ZIPCODE" as const,
    /**
     * Description: Town location of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_TOWN_LOCATION: "ADDRESS_TOWN_LOCATION" as const,
    /**
     * Description: Town district of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_TOWN_DISTRICT: "ADDRESS_TOWN_DISTRICT" as const,
    /**
     * Description: Country subdivision of the individual or business.
     *
     * GANA Type: String
     */
    ADDRESS_COUNTRY_SUBDIVISION: "ADDRESS_COUNTRY_SUBDIVISION" as const,
    /**
     * Description: Country name of of the individual or business.
     *
     * GANA Type: CountryCode
     */
    TAX_COUNTRY: "TAX_COUNTRY" as const,
    /**
     * Description: Tax identifier of the individual or business.
     *
     * GANA Type: String
     */
    TAX_ID: "TAX_ID" as const,
    /**
     * Description: Is business founded or under USA law.
     *
     * GANA Type: Boolean
     */
    TAX_IS_USA_LAW: "TAX_IS_USA_LAW" as const,
    /**
     * Description: Is the individual or business economically active or passive.
     *
     * GANA Type: Boolean
     */
    TAX_IS_ACTIVE: "TAX_IS_ACTIVE" as const,
    /**
     * Description: Is the business entitled to deduct input tax.
     *
     * GANA Type: Boolean
     */
    TAX_IS_DEDUCTED: "TAX_IS_DEDUCTED" as const,
    /**
     * Description: Name of the version of the terms of service accepted by the customer.
     *
     * GANA Type: Boolean
     */
    ACCEPTED_TERMS_OF_SERVICE: "ACCEPTED_TERMS_OF_SERVICE" as const,
    /**
     * Description: True if the customer read or downloaded the terms of service. It should be set automatically.
     *
     * GANA Type: Boolean
     */
    DOWNLOADED_TERMS_OF_SERVICE: "DOWNLOADED_TERMS_OF_SERVICE" as const,
    /**
     * Description: Current note on the GWG file.
     *
     * GANA Type: String
     */
    FILE_NOTE: "FILE_NOTE" as const,
    /**
     * Description: Customer name or internal alias.
     *
     * GANA Type: String
     */
    CUSTOMER_LABEL: "CUSTOMER_LABEL" as const,
    /**
     * Description: Boolean flag indicating whether the account has been opened. The definition of opening an account is deployment-specific.
     *
     * GANA Type: Boolean
     */
    AML_ACCOUNT_OPEN: "AML_ACCOUNT_OPEN" as const,
    /**
     * Description: True if the customer is a domestic PEP.
     *
     * GANA Type: Boolean
     */
    AML_DOMESTIC_PEP: "AML_DOMESTIC_PEP" as const,
    /**
     * Description: True if the customer is a foreign PEP.
     *
     * GANA Type: Boolean
     */
    AML_FOREIGN_PEP: "AML_FOREIGN_PEP" as const,
    /**
     * Description: True if the customer is a international organization PEP.
     *
     * GANA Type: Boolean
     */
    AML_INTERNATIONAL_ORG_PEP: "AML_INTERNATIONAL_ORG_PEP" as const,
    /**
     * Description: True if the customer is a  high-risk business.
     *
     * GANA Type: Boolean
     */
    AML_HIGH_RISK_BUSINESS: "AML_HIGH_RISK_BUSINESS" as const,
    /**
     * Description: True if the customer is associated with a high-risk country.
     *
     * GANA Type: Boolean
     */
    AML_HIGH_RISK_COUNTRY: "AML_HIGH_RISK_COUNTRY" as const,
    /**
     * Description: The MROS reporting state for the account.
     *
     * GANA Type: String
     */
    AML_MROS_STATE: "AML_MROS_STATE" as const,
    /**
     * Description: Name of the form completed by the user.
     *
     * GANA Type: String
     */
    FORM_ID: "FORM_ID" as const,
    /**
     * Description: Version of the form completed by the user.
     *
     * GANA Type: Number
     */
    FORM_VERSION: "FORM_VERSION" as const,
    /**
     * Description: High entropy value used in forms where hash is going to be stored in plain text.
     *
     * GANA Type: String
     */
    FORM_SALT: "FORM_SALT" as const,
    /**
     * Description: List of supplemental file attachments.
     *
     * GANA Type: List of records, usually the file upload and a description.
     */
    SUPPLEMENTAL_FILES_LIST: "SUPPLEMENTAL_FILES_LIST" as const,
    /**
     * Description: Generic note description
     *
     * GANA Type: String
     */
    NOTE_TEXT: "NOTE_TEXT" as const,


}
