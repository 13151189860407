import { AccountProperties } from "@gnu-taler/taler-util";
import { TalerFormAttributes } from "../taler-form-attributes.js";
import { LegitimizationRuleSet } from "../types-taler-kyc-aml.js";

/**
 * List of account properties required by TOPS
 */

export const TOPS_AccountProperties = [
  TalerFormAttributes.FILE_NOTE,
  TalerFormAttributes.CUSTOMER_LABEL,
  TalerFormAttributes.AML_ACCOUNT_OPEN,
  TalerFormAttributes.AML_DOMESTIC_PEP,
  TalerFormAttributes.AML_FOREIGN_PEP,
  TalerFormAttributes.AML_INTERNATIONAL_ORG_PEP,
  TalerFormAttributes.AML_HIGH_RISK_BUSINESS,
  TalerFormAttributes.AML_HIGH_RISK_COUNTRY,
  TalerFormAttributes.AML_MROS_STATE,
] as const;

/**
 * List of account properties required by GLS
 */
export const GLS_AccountProperties = [TalerFormAttributes.FILE_NOTE] as const;

export type PropertiesMapInfo<T extends string> = {
  [name in T]: {
    /**
     * Based on all the current properties, the current account limits and
     * new attribues of the account calculate if the property should
     * change. The value "undefined" means no change.
     *
     * @param limits
     * @param state
     * @param newAttributes
     * @returns
     */
    deriveProperty: (
      limits: LegitimizationRuleSet,
      state: AccountProperties,
      newAttributes: Record<string, unknown>,
    ) => string | boolean | undefined;
  };
};

export const TOPS_AML_PROPERTIES: PropertiesMapInfo<
  (typeof TOPS_AccountProperties)[number]
> = {
  AML_ACCOUNT_OPEN: {
    deriveProperty(limits, state, attributes) {
      return !!attributes[TalerFormAttributes.AML_ACCOUNT_OPEN];
    },
  },
  AML_DOMESTIC_PEP: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
  AML_FOREIGN_PEP: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
  AML_INTERNATIONAL_ORG_PEP: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
  AML_HIGH_RISK_BUSINESS: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
  AML_HIGH_RISK_COUNTRY: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
  AML_MROS_STATE: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
  CUSTOMER_LABEL: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
  FILE_NOTE: {
    deriveProperty(limits, state, attributes) {
      return undefined;
    },
  },
};

export const GLS_AML_PROPERTIES: PropertiesMapInfo<
  (typeof GLS_AccountProperties)[number]
> = {
  FILE_NOTE: {
    deriveProperty(limits, state, attributes) {
      return !!attributes[TalerFormAttributes.AML_ACCOUNT_OPEN];
    },
  },
};
