/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { Duration, TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
  TalerForm,
} from "../../../components/form/FormProvider.js";
import { DefaultInstanceFormFields } from "../../../components/instance/DefaultInstanceFormFields.js";
import { useSessionContext } from "../../../context/session.js";
import { undefinedIfEmpty } from "../../../utils/table.js";

export type Entity = Omit<Omit<TalerMerchantApi.InstanceReconfigurationMessage, "default_pay_delay">, "default_wire_transfer_delay"> & {
  default_pay_delay: Duration,
  default_wire_transfer_delay: Duration,
} & TalerForm;

export interface Props {
  onUpdate: (d: TalerMerchantApi.InstanceReconfigurationMessage) => void;
  selected: TalerMerchantApi.QueryInstancesResponse;
  isLoading: boolean;
  onBack: () => void;
}

function convert(
  from: TalerMerchantApi.QueryInstancesResponse,
): Entity {
  const { default_pay_delay, default_wire_transfer_delay, ...rest } = from;

  const defaults = {
    use_stefan: false,
    default_pay_delay: Duration.fromTalerProtocolDuration(default_pay_delay),
    default_wire_transfer_delay: Duration.fromTalerProtocolDuration(default_wire_transfer_delay),
  };
  return { ...defaults, ...rest } as Entity;
}

export function UpdatePage({
  onUpdate,
  selected,
  onBack,
}: Props): VNode {
  const { state } = useSessionContext();

  const [value, valueHandler] = useState<Partial<Entity>>(convert(selected));

  const { i18n } = useTranslationContext();

  const errors = undefinedIfEmpty<FormErrors<Entity>>({
    name: !value.name ? i18n.str`Required` : undefined,
    user_type: !value.user_type
      ? i18n.str`Required`
      : value.user_type !== "business" && value.user_type !== "individual"
        ? i18n.str`Must be business or individual`
        : undefined,
    default_pay_delay: !value.default_pay_delay
      ? i18n.str`Required`
      : !!value.default_wire_transfer_delay &&
        value.default_wire_transfer_delay.d_ms !== "forever" &&
        value.default_pay_delay.d_ms !== "forever" &&
        value.default_pay_delay.d_ms > value.default_wire_transfer_delay.d_ms ?
        i18n.str`Pay delay can't be greater than wire transfer delay` : undefined,
    default_wire_transfer_delay: !value.default_wire_transfer_delay
      ? i18n.str`Required`
      : undefined,
    address: undefinedIfEmpty({
      address_lines:
        value.address?.address_lines && value.address?.address_lines.length > 7
          ? i18n.str`Max 7 lines`
          : undefined,
    }),
    jurisdiction: undefinedIfEmpty({
      address_lines:
        value.address?.address_lines && value.address?.address_lines.length > 7
          ? i18n.str`Max 7 lines`
          : undefined,
    }),
  });

  const hasErrors = errors !== undefined;

  const submit = async (): Promise<void> => {
    const { default_pay_delay, default_wire_transfer_delay, ...rest } = value as Required<Entity>;
    const result: TalerMerchantApi.InstanceReconfigurationMessage = {
      default_pay_delay: Duration.toTalerProtocolDuration(default_pay_delay),
      default_wire_transfer_delay: Duration.toTalerProtocolDuration(default_wire_transfer_delay),
      ...rest,
    }
    onUpdate(result);
  };
  // const [active, setActive] = useState(false);

  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    <i18n.Translate>Instance id</i18n.Translate>: <b>{state.instance}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <hr />

        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider<Entity>
              errors={errors}
              object={value}
              valueHandler={valueHandler}
            >
              <DefaultInstanceFormFields showId={false} />
            </FormProvider>

            <div class="buttons is-right mt-4">
              <button
                class="button"
                onClick={onBack}
                data-tooltip="cancel operation"
              >
                <i18n.Translate>Cancel</i18n.Translate>
              </button>

              <AsyncButton
                onClick={submit}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : i18n.str`Confirm operation`
                }
                disabled={hasErrors}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
