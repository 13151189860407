import { AmountJson, Amounts, TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { UIFormProps } from "../FormProvider.js";
import { InputLine } from "./InputLine.js";

export function InputAmount(
  props: { currency: string } & UIFormProps<AmountJson>,
): VNode {
  return (
    <InputLine
      {...props}
      type="text"
      before={{
        type: "text",
        text: props.currency as TranslatedString,
      }}
      //@ts-ignore
      converter={
        props.converter ?? {
          fromStringUI: (v): AmountJson => {
            return (
              Amounts.parse(`${props.currency}:${v}`) ??
              Amounts.zeroOfCurrency(props.currency)
            );
          },
          toStringUI: (v: AmountJson) => {
            return v === undefined ? "" : Amounts.stringifyValue(v);
          },
        }
      }
    />
  );
}
