import { TalerFormAttributes } from "@gnu-taler/taler-util";
import {
  DoubleColumnFormDesign,
  FormMetadata,
  InternationalizationAPI,
} from "../../index.browser.js";

export const form_vqf_902_14 = (
  i18n: InternationalizationAPI,
): FormMetadata => ({
  label: i18n.str`Special Clarifications`,
  description: i18n.str`When a business relationship or transaction is associated with increased risk, appears unusual or evidence exists that the assets are the proceeds of a felony or a qualified tax offence, the member has to perform additional clarifications.`,
  id: "vqf_902_14",
  version: 1,
  config: VQF_902_14(i18n),
});

export function VQF_902_14(
  i18n: InternationalizationAPI,
): DoubleColumnFormDesign {
  return {
    type: "double-column",
    title: "Special Clarifications",
    sections: [
      {
        title: i18n.str`Reason for special clarifications`,
        fields: [
          {
            id: TalerFormAttributes.INCRISK_REASON,
            label: i18n.str`Reason`,
            help: i18n.str`Description of the circumstances/transactions, which triggered the special clarifications`,
            type: "textArea",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Used means of clarification`,
        fields: [
          {
            id: TalerFormAttributes.INCRISK_MEANS,
            label: i18n.str`Means`,
            type: "choiceStacked",
            choices: [
              {
                value: "GATHERING",
                label: i18n.str`Gathering of information from the customer, beneficial owner of the assets, controlling person`,
              },
              {
                value: "CONSULTATION",
                label: i18n.str`Consultation of generally accessible sources and databases`,
              },
              {
                value: "ENQUIRIES",
                label: i18n.str`Enquiries with trustworthy persons`,
              },
              {
                value: "OTHER",
                label: i18n.str`Other, which?`,
              },
            ],
            required: true,
          },
          {
            id: TalerFormAttributes.INCRISK_MEANS_OTHER,
            type: "text",
            label: i18n.str`Other means of clarification:`,
            required: true,
            hide(value, root) {
              return root[TalerFormAttributes.INCRISK_MEANS] !== "OTHER";
            },
          },
        ],
      },
      {
        title: i18n.str`Supplemental File Upload`,
        description: i18n.str`Optional supplemental information for the special clarifications.`,
        fields: [
          {
            id: TalerFormAttributes.SUPPLEMENTAL_FILES_LIST,
            label: i18n.str`Supplemental Files`,
            type: "array",
            labelFieldId: "FILE.FILENAME",
            required: false,
            fields: [
              {
                id: TalerFormAttributes.DESCRIPTION,
                label: i18n.str`Description`,
                type: "textArea",
                required: true,
              },
              {
                id: TalerFormAttributes.FILE,
                label: i18n.str`File (PDF)`,
                type: "file",
                accept: "application/pdf",
                required: true,
              },
            ],
          },
        ],
      },
      {
        title: i18n.str`Summary and plausbility check of the gathered information`,
        description: i18n.str`The results of the clarifications have to be documented and their plausability has to be checked.`,
        fields: [
          {
            id: TalerFormAttributes.INCRISK_SUMMARY,
            label: i18n.str`Summary`,
            type: "textArea",
            required: true,
          },
          {
            id: TalerFormAttributes.INCRISK_DOCUMENTS,
            label: i18n.str`Gathered/Consulted documents`,
            type: "textArea",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Result of the special clarification`,
        fields: [
          {
            id: TalerFormAttributes.INCRISK_RESULT,
            label: i18n.str`Result`,
            type: "choiceStacked",
            choices: [
              {
                label: i18n.str`No suspicion`,
                value: "NO_SUSPICION",
                description: i18n.str`The plausibility of the circumstances could be checked, no reasonable suspicion pursuant to Art. 9 AMLA (possibly update of customer profile and/or risk profile)`,
              },
              {
                label: i18n.str`Reasonable suspicion`,
                value: "REASONABLE_SUSPICION",
                description: i18n.str`Reasonable suspicion pursuant to Art. 9 AMLA, duty to file a report with MROS`,
              },
              {
                label: i18n.str`Simple suspicion`,
                value: "SIMPLE_SUSPICION",
                description: i18n.str`Simple suspicion pursuant to Art. 305 Para. 2 StGB, right to notify MROS`,
              },
              {
                label: i18n.str`Other, what?`,
                value: "OTHER",
              },
            ],
            required: true,
          },
          {
            id: TalerFormAttributes.INCRISK_RESULT_OTHER,
            type: "text",
            label: i18n.str`Result clarification`,
            required: true,
            hide(value, root) {
              return root[TalerFormAttributes.INCRISK_RESULT] !== "OTHER";
            },
          },
        ],
      },
    ],
  };
}
