import { TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { Addon } from "./FormProvider.js";
import {
  LabelWithTooltipMaybeRequired,
  RenderAddon,
} from "./fields/InputLine.js";
import { RenderAllFieldsByUiConfig } from "./forms-ui.js";
import { UIFormField } from "./field-types.js";

interface Props {
  label: TranslatedString;
  tooltip?: TranslatedString;
  help?: TranslatedString;
  before?: Addon;
  after?: Addon;
  fields: UIFormField[];
}

export function Group({
  before,
  after,
  label,
  tooltip,
  help,
  fields,
}: Props): VNode {
  return (
    <div class="sm:col-span-6 p-4 rounded-lg border-r-2 border-2 bg-gray-50">
      {before !== undefined && <RenderAddon addon={before} />}
      <LabelWithTooltipMaybeRequired label={label} tooltip={tooltip} />
      {after !== undefined && <RenderAddon addon={after} />}
      {help && (
        <p class="mt-2 text-sm text-gray-500" id="email-description">
          {help}
        </p>
      )}
      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
        <RenderAllFieldsByUiConfig fields={fields} />
      </div>
    </div>
  );
}
