/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */
import { ComponentChildren, h, VNode } from "preact";
import { InputProps, useField } from "./useField.js";

interface Props<T> extends InputProps<T> {
  name: T;
  readonly?: boolean;
  expand?: boolean;
  threeState?: boolean;
  side?: ComponentChildren;
  toBoolean?: (v?: any) => boolean | undefined;
  fromBoolean?: (s: boolean | undefined) => any;
}

const defaultToBoolean = (f?: any): boolean | undefined => f;
const defaultFromBoolean = (v: boolean | undefined): any => v as any;

export function InputToggle<T>({
  name,
  readonly,
  placeholder,
  tooltip,
  label,
  help,
  threeState,
  side,
  expand,
  fromBoolean = defaultFromBoolean,
  toBoolean = defaultToBoolean,
}: Props<keyof T>): VNode {
  const { error, value, onChange } = useField<T>(name);

  const onCheckboxClick = (): void => {
    const c = toBoolean(value);
    if (c === false && threeState) return onChange(undefined as any);
    return onChange(fromBoolean(!c));
  };

  return (
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          {label}
          {tooltip && (
            <span class="icon has-tooltip-right" data-tooltip={tooltip}>
              <i class="mdi mdi-information" />
            </span>
          )}
        </label>
      </div>
      <div class="field-body is-flex-grow-3">
        <div class="field">
          <p class={expand ? "control is-expanded" : "control"}>
            <label class="toggle" style={{ marginLeft: 4, marginTop: 0 }}>
              <input
                type="checkbox"
                class={"toggle-checkbox"}
                checked={toBoolean(value)}
                placeholder={placeholder}
                ref={(d) => {
                  if (d) {
                    d.indeterminate =
                      !!threeState && toBoolean(value) === undefined;
                  }
                }}
                readonly={readonly}
                name={String(name)}
                disabled={readonly}
                onChange={onCheckboxClick}
              />

              <div
                class={`toggle-switch ${readonly ? "disabled" : ""} ${
                  toBoolean(value) === undefined ? "no-dot" : ""
                }`}
                style={{ cursor: readonly ? "default" : undefined }}
              ></div>
            </label>
            <p>{help}</p>
          </p>
          {error && (
            <p class="help is-danger" style={{ fontSize: 16 }}>
              {error}
            </p>
          )}
        </div>
        {side}
      </div>
    </div>
  );
}
