/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputSelector } from "../../../../components/form/InputSelector.js";
import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { undefinedIfEmpty } from "../../../../utils/table.js";

type Entity = TalerMerchantApi.WebhookAddDetails;

interface Props {
  onCreate: (d: Entity) => Promise<void>;
  onBack?: () => void;
}

const validType = ["pay", "refund"];
const validMethod = ["GET", "POST", "PUT", "PATCH", "HEAD"];

export function CreatePage({ onCreate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<Entity>>({});

  const errors = undefinedIfEmpty<FormErrors<Entity>>({
    webhook_id: !state.webhook_id ? i18n.str`Required` : undefined,
    event_type: !state.event_type
      ? i18n.str`Required`
      : validType.indexOf(state.event_type) === -1
        ? i18n.str`Must be one of '${validType.join(", ")}'`
        : undefined,
    http_method: !state.http_method
      ? i18n.str`Required`
      : validMethod.indexOf(state.http_method) === -1
        ? i18n.str`Must be one of '${validMethod.join(", ")}'`
        : undefined,
    url: !state.url
      ? i18n.str`Required`
      : isInvalidUrl(state.url)
        ? i18n.str`URL is invalid`
        : undefined,
  });

  const hasErrors = errors !== undefined;

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    return onCreate(state as TalerMerchantApi.WebhookAddDetails);
  };

  return (
    <div>
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <Input<Entity>
                name="webhook_id"
                label={i18n.str`ID`}
                tooltip={i18n.str`Webhook ID to use`}
              />
              <InputSelector
                name="event_type"
                label={i18n.str`Event`}
                values={["choose", ...validType]}
                toStr={(v) => {
                  const idx = validType.indexOf(v);
                  if (idx === -1) return i18n.str`Choose one...`;
                  return [i18n.str`Pay`, i18n.str`Refund`][idx];
                }}
                tooltip={i18n.str`The event of the webhook: why the webhook is used`}
              />
              <InputSelector
                name="http_method"
                label={i18n.str`Method`}
                toStr={(v) => {
                  const idx = validMethod.indexOf(v);
                  if (idx === -1) return i18n.str`Choose one...`;
                  return [
                    i18n.str`GET`,
                    i18n.str`POST`,
                    i18n.str`PUT`,
                    i18n.str`PATCH`,
                    i18n.str`HEAD`,
                  ][idx];
                }}
                values={["choose", ...validMethod]}
                tooltip={i18n.str`Method used by the webhook`}
              />

              <Input<Entity>
                name="url"
                label={i18n.str`URL`}
                tooltip={i18n.str`URL of the webhook where the customer will be redirected`}
              />

              <p>
                {/* prettier will add some nodes which we don't want because of i18n */}
                {/* prettier-ignore */}
                <i18n.Translate>
                  The text below supports the <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://mustache.github.io/mustache.5.html"
                  >
                    mustache
                  </a> template engine. Any string between <pre style={{ display: "inline", padding: 0 }}>
                    &#123;&#123;
                  </pre> and <pre style={{ display: "inline", padding: 0 }}>
                    &#125;&#125;
                  </pre> will be replaced with the value of the corresponding variable.
                </i18n.Translate>
              </p>
              <p>
                {/* prettier will add some nodes which we don't want because of i18n */}
                {/* prettier-ignore */}
                <i18n.Translate>
                  For example <pre style={{ display: "inline", padding: 0 }}>
                    &#123;&#123;contract_terms.amount&#125;&#125;
                  </pre> will be replaced with the the order's price
                </i18n.Translate>
              </p>
              <p>
                <i18n.Translate>
                  The short list of variables are:
                </i18n.Translate>
              </p>
              <div class="menu">
                <ul
                  class="menu-list"
                  style={{ listStyleType: "disc", marginLeft: 20 }}
                >
                  <li>
                    <b>contract_terms.summary:</b>{" "}
                    <i18n.Translate>order's description</i18n.Translate>
                  </li>
                  <li>
                    <b>contract_terms.amount:</b>{" "}
                    <i18n.Translate>order's price</i18n.Translate>
                  </li>
                  <li>
                    <b>order_id:</b>{" "}
                    <i18n.Translate>
                      order's unique identification
                    </i18n.Translate>
                  </li>
                  {state.event_type === "refund" && (
                    <Fragment>
                      <li>
                        <b>refund_amout:</b>{" "}
                        <i18n.Translate>
                          the amount that was being refunded
                        </i18n.Translate>
                      </li>
                      <li>
                        <b>reason:</b>{" "}
                        <i18n.Translate>
                          the reason entered by the merchant staff for granting
                          the refund
                        </i18n.Translate>
                      </li>
                      <li>
                        <b>timestamp:</b>{" "}
                        <i18n.Translate>
                          time of the refund in nanoseconds since 1970
                        </i18n.Translate>
                      </li>
                    </Fragment>
                  )}
                </ul>
              </div>
              {/* <Input<Entity>
                name="header_template"
                label={i18n.str`Http header`}
                inputType="multiline"
                tooltip={i18n.str`Header template of the webhook`}
              /> */}
              <Input<Entity>
                name="body_template"
                inputType="multiline"
                label={i18n.str`Http body`}
                tooltip={i18n.str`Body template by the webhook`}
              />
            </FormProvider>

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                disabled={hasErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : i18n.str`Confirm operation`
                }
                onClick={submitForm}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}

function isInvalidUrl(url: string) {
  try {
    const asd = new URL("./", url);
    return false;
  } catch (e) {
    return true;
  }
}
