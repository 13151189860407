import { AbsoluteTime } from "@gnu-taler/taler-util";
import { format, parse } from "date-fns";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { Calendar } from "../Calendar.js";
import { Dialog } from "../Dialog.js";
import { UIFormProps } from "../FormProvider.js";
import { noHandlerPropsAndNoContextForField } from "./InputArray.js";
import { InputLine } from "./InputLine.js";

export function InputAbsoluteTime(
  properties: { pattern?: string } & UIFormProps<AbsoluteTime>,
): VNode {
  const pattern = properties.pattern ?? "dd/MM/yyyy";
  const [open, setOpen] = useState(false);

  const { value, onChange } =
    properties.handler ?? noHandlerPropsAndNoContextForField(properties.name);
  return (
    <Fragment>
      <InputLine
        type="text"
        focus={properties.focus}
        after={{
          type: "button",
          onClick: () => {
            setOpen(true);
          },
          // icon: <CalendarIcon class="h-6 w-6" />,
          children: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
          ),
        }}
        converter={{
          //@ts-ignore
          fromStringUI: (v): AbsoluteTime | undefined => {
            if (!v) return undefined;
            try {
              const t_ms = parse(v, pattern, Date.now()).getTime();
              return AbsoluteTime.fromMilliseconds(t_ms);
            } catch (e) {
              return undefined;
            }
          },
          //@ts-ignore
          toStringUI: (v: AbsoluteTime | undefined) => {
            return !v || !v.t_ms
              ? undefined
              : v.t_ms === "never"
                ? "never"
                : format(v.t_ms, pattern);
          },
        }}
        {...properties}
      />
      {open && (
        <Dialog onClose={() => setOpen(false)}>
          <Calendar
            value={(value as any as AbsoluteTime) ?? AbsoluteTime.now()}
            onChange={(v) => {
              onChange(v as any);
              setOpen(false);
            }}
          />
        </Dialog>
      )}
      {/* {open &&
        <Dialog onClose={() => setOpen(false)} >
          <TimePicker value={value as AbsoluteTime ?? AbsoluteTime.now()}
            onChange={(v) => {
              onChange(v as any)
            }}
            onConfirm={() => {
              setOpen(false)
            }} />
        </Dialog>} */}
    </Fragment>
  );
}
