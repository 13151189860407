/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { i18n, setupI18n } from "@gnu-taler/taler-util";
import { ComponentChildren, createContext, h, VNode } from "preact";
import { useContext, useEffect } from "preact/hooks";
import { useLang } from "../hooks/index.js";
import { Locale } from "date-fns";
import {
  es as esLocale,
  enGB as enLocale,
  fr as frLocale,
  de as deLocale,
} from "date-fns/locale";

export type InternationalizationAPI = typeof i18n;

interface Type {
  lang: string;
  supportedLang: { [id in keyof typeof supportedLang]: string };
  changeLanguage: (l: string) => void;
  i18n: InternationalizationAPI;
  dateLocale: Locale;
  completeness: { [id in keyof typeof supportedLang]: number };
}

const supportedLang = {
  es: "Espanol [es]",
  en: "English [en]",
  fr: "Francais [fr]",
  de: "Deutsch [de]",
  sv: "Svenska [sv]",
  it: "Italiane [it]",
};

const initial: Type = {
  lang: "en",
  supportedLang,
  changeLanguage: () => {
    // do not change anything
  },
  i18n,
  dateLocale: enLocale,
  completeness: {
    de: 0,
    en: 0,
    es: 0,
    fr: 0,
    it: 0,
    sv: 0,
  },
};
const Context = createContext<Type>(initial);

interface Props {
  initial?: string;
  children: ComponentChildren;
  /** ONLY USER FOR TESTING */
  forceLang__testing?: string;
  source: Record<string, any>;
  completeness?: Record<string, number>;
}

// Outmost UI wrapper.
export const TranslationProvider = ({
  initial,
  children,
  forceLang__testing: forceLang,
  source,
  completeness: completenessProp,
}: Props): VNode => {
  const completeness = {
    en: 100,
    de:
      !completenessProp || !completenessProp["de"] ? 0 : completenessProp["de"],
    es:
      !completenessProp || !completenessProp["es"] ? 0 : completenessProp["es"],
    fr:
      !completenessProp || !completenessProp["fr"] ? 0 : completenessProp["fr"],
    it:
      !completenessProp || !completenessProp["it"] ? 0 : completenessProp["it"],
    sv:
      !completenessProp || !completenessProp["sv"] ? 0 : completenessProp["sv"],
  };
  const { value: lang, update: changeLanguage } = useLang(
    initial,
    completeness,
  );

  useEffect(() => {
    if (forceLang) {
      changeLanguage(forceLang);
    }
  }, [forceLang]);
  useEffect(() => {
    setupI18n(lang, source);
  }, [lang]);
  if (forceLang) {
    setupI18n(forceLang, source);
  } else {
    setupI18n(lang, source);
  }

  const dateLocale =
    lang === "es"
      ? esLocale
      : lang === "fr"
        ? frLocale
        : lang === "de"
          ? deLocale
          : enLocale;

  return h(Context.Provider, {
    value: {
      lang,
      changeLanguage,
      supportedLang,
      i18n,
      dateLocale,
      completeness,
    },
    children,
  });
};

export const useTranslationContext = (): Type => useContext(Context);
