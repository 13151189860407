import { TalerFormAttributes } from "@gnu-taler/taler-util";
import {
  DoubleColumnFormDesign,
  FormMetadata,
  InternationalizationAPI,
} from "../../index.browser.js";

export const form_vqf_902_4 = (
  i18n: InternationalizationAPI,
): FormMetadata => ({
  label: i18n.str`Risk Profile AMLA`,
  id: "vqf_902_4",
  version: 1,
  config: VQF_902_4(i18n),
});

export function VQF_902_4(
  i18n: InternationalizationAPI,
): DoubleColumnFormDesign {
  return {
    type: "double-column",
    sections: [
      {
        title: i18n.str`Evaluation of politically exposed persons (PEP-Check)`,
        description: i18n.str`This evaluation has to be completed by all members for every business relationship.`,
        fields: [
          {
            id: TalerFormAttributes.PEP_FOREIGN,
            label: i18n.str`Foreign PEP`,
            help: i18n.str`Is the customer, the beneficial owner or the controlling person or authorised representative a foreign PEP or closely related to such a person?`,
            type: "choiceHorizontal",
            required: true,
            choices: [
              {
                value: true,
                label: `Yes`,
              },
              {
                value: false,
                label: `No`,
              },
            ],
          },
          {
            id: TalerFormAttributes.PEP_DOMESTIC,
            label: i18n.str`Domestic PEP`,
            help: i18n.str`Is the customer, the beneficial owner or the controlling person or authorised representative a domestic PEP or closely related to such a person?`,
            type: "choiceHorizontal",
            required: true,
            choices: [
              {
                value: true,
                label: `Yes`,
              },
              {
                value: false,
                label: `No`,
              },
            ],
          },
          {
            id: TalerFormAttributes.PEP_INTERNATIONAL_ORGANIZATION,
            label: i18n.str`PEP of International Organisatons`,
            help: i18n.str`Is the customer, the beneficial owner or the controlling person or authorised representative a PEP in International Organizations or closely related to such a person?`,
            type: "choiceHorizontal",
            required: true,
            choices: [
              {
                value: true,
                label: `Yes`,
              },
              {
                value: false,
                label: `No`,
              },
            ],
          },
          {
            id: TalerFormAttributes.PEP_ACCEPTANCE_DATE,
            label: i18n.str`Acceptance date`,
            help: i18n.str`When the decision of the Senior executive body on the acceptance of a business relationship with a PEP was obtain on.`,
            type: "isoDateText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            required: true,
            hide(value, root) {
              return !(
                root[TalerFormAttributes.PEP_FOREIGN] ||
                root[TalerFormAttributes.PEP_DOMESTIC] ||
                root[TalerFormAttributes.PEP_INTERNATIONAL_ORGANIZATION]
              );
            },
          },
        ],
      },
      {
        title: i18n.str`Evaluation "high risk" or non-cooperative country`,
        description: i18n.str`This evaluation has to be completed by all members for every business relationship.`,
        fields: [
          {
            id: TalerFormAttributes.HIGH_RISK_COUNTRY,
            label: i18n.str`High-risk or non-cooperative country`,
            help: i18n.str`Is the customer, the beneficial owner or the controlling person or authorised representative in a country considered by the FATF as high-risk or non-cooperative and for which FATF requires increased diligence?`,
            type: "choiceHorizontal",
            required: true,
            choices: [
              {
                value: true,
                label: `Yes`,
              },
              {
                value: false,
                label: `No`,
              },
            ],
          },
          {
            id: TalerFormAttributes.HIGH_RISK_ACCEPTANCE_DATE,
            label: i18n.str`Acceptance date`,
            help: i18n.str`When the decision of the Senior executive body on the acceptance of such a business relationship was obtained on.`,
            type: "isoDateText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            required: true,
            hide(value, root) {
              return !root[TalerFormAttributes.HIGH_RISK_COUNTRY];
            },
          },
        ],
      },
      {
        title: i18n.str`Evaluation of business relationship risk`,
        description: i18n.str``,
        fields: [
          {
            type: "caption",
            label:
              "This evaluation has to be completed by all members who have in total more than 20 customers for every business relationship. At least two risk categories have to be chosen and assessed.",
          },
        ],
      },
      {
        title: i18n.str`Country risk`,
        fields: [
          {
            id: TalerFormAttributes.COUNTRY_RISK_NATIONALITY_TYPE,
            label: i18n.str`Applicable country risk types`,
            choices: [
              {
                label: i18n.str`Nationality of the customer`,
                value: "NATIONALITY_CUSTOMER",
              },
              {
                label: i18n.str`Nationality of the beneficial owner of the assets`,
                value: "NATIONALITY_OWNER",
              },
              {
                label: i18n.str`Domicile/residential address of the customer`,
                value: "DOMICILE_CUSTOMER",
              },
              {
                label: i18n.str`Domicile/residential address of the beneficial owner of the assets`,
                value: "DOMICILE_OWNER",
              },
              {
                label: i18n.str`Domicile/residential address of the controlling person`,
                value: "DOMICILE_CONTROLLING",
              },
            ],
            type: "selectMultiple",
            required: false,
          },
          {
            id: TalerFormAttributes.COUNTRY_RISK_NATIONALITY_LEVEL,
            label: i18n.str`Country risk level (nationality)`,
            help: i18n.str`Risk category according to VQF country list (VQF doc. no. 902.4.1)`,
            choices: [
              {
                label: i18n.str`Low (Risk 0)`,
                value: "LOW",
                description: i18n.str`Risk 0 acc. to VQF country list (VQF doc. no. 902.4.1)`,
              },
              {
                label: i18n.str`Medium (Risk 1)`,
                value: "MEDIUM",
                description: i18n.str`Risk 1 acc. to VQF country list (VQF doc. no. 902.4.1)`,
              },
              {
                label: i18n.str`High (Risk 2)`,
                value: "HIGH",
                description: i18n.str`Risk 2 acc. to VQF country list (VQF doc. no. 902.4.1)`,
              },
            ],
            type: "choiceHorizontal",
            required: false,
          },
          {
            id: TalerFormAttributes.COUNTRY_RISK_BUSINESS_TYPE,
            label: i18n.str`Country risk type (place of business activity)`,
            choices: [
              {
                label: i18n.str`Customer`,
                value: "CUSTOMER",
              },
              {
                label: i18n.str`Beneficial owner`,
                value: "OWNER",
              },
            ],
            type: "selectMultiple",
            required: false,
          },
          {
            id: TalerFormAttributes.COUNTRY_RISK_BUSINESS_LEVEL,
            label: i18n.str`Country risk level (business activity)`,
            help: i18n.str`Risk category according to VQF country list (VQF doc. no. 902.4.1)`,
            choices: [
              {
                label: i18n.str`Low (Risk 0)`,
                value: "LOW",
                description: i18n.str`Risk 0 acc. to VQF country list (VQF doc. no. 902.4.1)`,
              },
              {
                label: i18n.str`Medium (Risk 1)`,
                value: "MEDIUM",
                description: i18n.str`Risk 1 acc. to VQF country list (VQF doc. no. 902.4.1)`,
              },
              {
                label: i18n.str`High (Risk 2)`,
                value: "HIGH",
                description: i18n.str`Risk 2 acc. to VQF country list (VQF doc. no. 902.4.1)`,
              },
            ],
            type: "choiceHorizontal",
          },
        ],
      },
      {
        title: i18n.str`Industry risk`,
        fields: [
          {
            id: TalerFormAttributes.INDUSTRY_RISK_TYPE,
            label: i18n.str`Industry risk source`,
            type: "selectMultiple",
            choices: [
              { label: i18n.str`Customer`, value: "CUSTOMER" },
              {
                label: i18n.str`Beneficial owner of the assets`,
                value: "OWNER",
              },
            ],
            required: false,
          },
          {
            id: TalerFormAttributes.INDUSTRY_RISK_LEVEL,
            label: i18n.str`Industry risk level`,
            type: "choiceStacked",
            choices: [
              {
                label: `Transparent (Risk Level 0)`,
                description: i18n.str`Clearly defined, transparent, easily comprehensible business activity well known to the member.`,
                value: "TRANSPARENT",
              },
              {
                label: `High cash transactions (Risk Level 1)`,
                description: i18n.str`Business activity with a high level of cash transactions.`,
                value: "HIGH_CASH_TRANSACTION",
              },
              {
                label: `Not well known (Risk Level 1)`,
                description: i18n.str`Business activity not well known to the member.`,
                value: "NOT_WELL_KNOWN",
              },
              {
                label: `High-risk trade (Risk Level 2)`,
                description: i18n.str`Trade in munitions/arms, raw gem stones/diamonds, jewellery, international trade in exotic animals, casino and lottery business, trade in erotic wares.`,
                value: "HIGH_RISK_TRADE",
              },
              {
                label: `Unknown industry (Risk Level 2)`,
                description: i18n.str`Member has no personal knowledge of the customer’s industry.`,
                value: "UNKNOWN_INDUSTRY",
              },
            ],
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Contact risk`,
        description: i18n.str`Type of contact to the customer/benefcial owner of the assets.`,
        fields: [
          {
            id: TalerFormAttributes.CONTACT_RISK_LEVEL,
            label: i18n.str`Contact risk level`,
            choices: [
              {
                label: "Low contact risk",
                description: i18n.str`Personal acquaintance between member and customer/beneficial owner of the assets over several years (at least 2) prior to entering into the business relationship`,
                value: "LOW",
              },
              {
                label: "Medium contact risk",
                description: i18n.str`The customer/beneficial owner was not personally known to the member for several years (at least 2) prior to entering into the business relationship; however (a) no business was entered into in the absence of the customer/beneficial owner, or (b) the customer was at least introduced/brokered by a trusted third party.`,
                value: "MEDIUM",
              },
              {
                label: "High contact risk",
                description: i18n.str`The customer/beneficial owner was not personally known to the member and business was entered into in the absence of the former (relationship by correspondence) and the customer was not introduced/brokered by a trusted third party.`,
                value: "HIGH",
              },
            ],
            type: "choiceStacked",
            required: false,
          },
        ],
      },
      {
        title: i18n.str`Summary evaluation`,
        fields: [
          {
            id: TalerFormAttributes.RISK_RATIONALY,
            label: i18n.str`Justification for risk assessment`,
            type: "textArea",
            required: false,
          },
          {
            id: TalerFormAttributes.RISK_CLASSIFICATION_LEVEL,
            label: i18n.str`Risk clasification`,
            help: i18n.str`Conclusion whether the business relationship is with or without increased risk.`,
            choices: [
              { label: i18n.str`No high risk`, value: "NO_HIGH_RISK" },
              { label: i18n.str`High risk`, value: "HIGH_RISK" },
            ],
            type: "choiceHorizontal",
            required: false,
          },
          {
            id: TalerFormAttributes.RISK_ACCEPTANCE_DATE,
            label: i18n.str`Acceptance date`,
            help: i18n.str`When the decision of the Senior executive body on the acceptance of a business relationship with increased risk was obtained on.`,
            type: "isoDateText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            required: true,
            hide(value, root) {
              return (
                root[TalerFormAttributes.RISK_CLASSIFICATION_LEVEL] !=
                "HIGH_RISK"
              );
            },
          },
        ],
      },
    ],
  };
}
