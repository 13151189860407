import { TalerFormAttributes } from "@gnu-taler/taler-util";
import {
  DoubleColumnFormDesign,
  FormMetadata,
  InternationalizationAPI,
} from "../../index.browser.js";

export const form_vqf_902_11 = (
  i18n: InternationalizationAPI,
): FormMetadata => ({
  label: i18n.str`Establishing of the controlling person of operating legal entities and partnerships both not quoted on the stock exchange (K)`,
  description: i18n.str`for operating legal entities and partnerships that are contracting partner as well as analogously for operating legal entities and partnership that are beneficial owners`,
  id: "vqf_902_11",
  version: 1,
  config: VQF_902_11(i18n),
});

export function VQF_902_11(
  i18n: InternationalizationAPI,
): DoubleColumnFormDesign {
  return {
    type: "double-column",
    title: i18n.str`Establishment of the controlling person`,
    sections: [
      {
        title: "Information about submitter",
        fields: [
          {
            id: TalerFormAttributes.SUBMITTED_BY,
            label: i18n.str`Who is filling out the form?`,
            type: "choiceHorizontal",
            required: true,
            choices: [
              {
                label: "Taler Operations staff",
                value: "AML_OFFICER",
              },
              {
                label: "The customer",
                value: "CUSTOMER",
              },
            ],
          },
        ],
      },
      {
        title: "Identity of the contracting partner",
        fields: [
          {
            id: TalerFormAttributes.IDENTITY_CONTRACTING_PARTNER,
            label: i18n.str`Contracting partner`,
            type: "textArea",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Controlling person(s)`,
        fields: [
          {
            id: TalerFormAttributes.CONTROL_REASON,
            label: i18n.str`Reason for control`,
            type: "choiceStacked",
            choices: [
              {
                value: "HAS_25_MORE_RIGHTS",
                label: i18n.str`Holding 25% or more`,
                description: i18n.str`The person(s) listed below is/are holding 25% or more of the contracting partner's shares (capital shares or voting rights)`,
              },
              {
                value: "OTHER_WAY",
                label: i18n.str`Other way`,
                description: i18n.str`If the capital shares or voting rights cannot be determined or in case there are no capital shares or voting rights 25% or more, the contracting partner hereby declares that the person(s) listed below is/are controlling the contracting partner in other ways`,
              },
              {
                value: "DIRECTOR",
                label: i18n.str`Managing director`,
                description: i18n.str`In case this/these person(s) cannot be determined or this/these person(s) does/do not exist, the contracting partner hereby declares that the person(s) listed below is/are the managing director(s)`,
              },
            ],
            required: true,
          },
          {
            id: TalerFormAttributes.IDENTITY_LIST,
            label: i18n.str`Controlling person(s)`,
            type: "array",
            validator(persons) {
              if (!persons || persons.length < 1) {
                return i18n.str`Can't be empty`;
              }
              return undefined;
            },
            labelFieldId: TalerFormAttributes.FULL_NAME,
            fields: [
              {
                id: TalerFormAttributes.FULL_NAME,
                label: i18n.str`Full name`,
                type: "text",
                required: true,
              },
              {
                id: TalerFormAttributes.DOMICILE_ADDRESS,
                label: i18n.str`Actual address of domicile`,
                type: "textArea",
                required: true,
              },
            ],
          },
        ],
      },
      {
        title: i18n.str`Fiduciary holding assets`,
        fields: [
          {
            id: TalerFormAttributes.CONTROLLING_ENTITY_THIRD_PERSON,
            label: i18n.str`Is a third person the beneficial owner of the assets held in the account/securities account?`,
            type: "toggle",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Signature(s)`,
        description: i18n.str`It is a criminal offence to deliberately provide false information on this form (article 251 of the Swiss Criminal Code, documents forgery)`,
        hide(root) {
          return root[TalerFormAttributes.SUBMITTED_BY] != "CUSTOMER";
        },
        fields: [
          {
            type: "caption",
            label: i18n.str`The contracting partner hereby undertakes to inform automatically of any changes to the information contained herein.`,
          },
          {
            id: TalerFormAttributes.SIGNATURE,
            label: i18n.str`Signature(s)`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.SIGN_DATE,
            label: i18n.str`Date`,
            type: "absoluteTimeText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Signed Declaration`,
        description: i18n.str`Signed declaration by the customer`,
        hide(root) {
          return root[TalerFormAttributes.SUBMITTED_BY] != "AML_OFFICER";
        },
        fields: [
          {
            type: "caption",
            label: i18n.str`The uploaded document must contain the customer's signature on the beneficial owner declaration.`,
          },
          {
            id: TalerFormAttributes.ATTACHMENT_SIGNED_DOCUMENT,
            label: i18n.str`Signed Document`,
            type: "file",
            accept: "application/pdf",
            required: true,
          },
        ],
      },
    ],
  };
}
