/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  Duration,
  MerchantAuthMethod,
  TalerMerchantApi,
  createRFC8959AccessTokenPlain,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../components/form/FormProvider.js";
import { Input } from "../../../components/form/Input.js";
import { InputToggle } from "../../../components/form/InputToggle.js";
import { DefaultInstanceFormFields } from "../../../components/instance/DefaultInstanceFormFields.js";
import { usePreference } from "../../../hooks/preference.js";
import { INSTANCE_ID_REGEX } from "../../../utils/constants.js";
import { undefinedIfEmpty } from "../../../utils/table.js";

export type Entity = TalerMerchantApi.InstanceConfigurationMessage & {
  auth_token?: string;
  // default_pay_delay: Duration;
  // default_wire_transfer_delay: Duration;
};

export interface Props {
  onCreate: (d: TalerMerchantApi.InstanceConfigurationMessage) => Promise<void>;
  onBack?: () => void;
  forceId?: string;
}

const twoHours = Duration.fromSpec({ hours: 2 });
const twoDays = Duration.fromSpec({ days: 2 });

function with_defaults(id?: string): Partial<Entity> {
  return {
    id,
    user_type: "business",
    use_stefan: true,
    default_pay_delay: Duration.toTalerProtocolDuration(twoHours),
    default_wire_transfer_delay: Duration.toTalerProtocolDuration(twoDays),
  };
}

type TokenForm = { accessControl: boolean; token: string; repeat: string };

export function CreatePage({ onCreate, onBack, forceId }: Props): VNode {
  const [pref, updatePref] = usePreference();
  const { i18n } = useTranslationContext();
  const [value, valueHandler] = useState(with_defaults(forceId));
  const [tokenForm, setTokenForm] = useState<Partial<TokenForm>>({});

  const errors = undefinedIfEmpty<FormErrors<Entity>>({
    id: !value.id
      ? i18n.str`Required`
      : !INSTANCE_ID_REGEX.test(value.id)
        ? i18n.str`Invalid`
        : undefined,
    name: !value.name ? i18n.str`Required` : undefined,

    user_type: !value.user_type
      ? i18n.str`Required`
      : value.user_type !== "business" && value.user_type !== "individual"
        ? i18n.str`Must be business or individual`
        : undefined,
    // accounts:
    //   !value.accounts || !value.accounts.length
    //     ? i18n.str`Required`
    //     : undefinedIfEmpty(
    //       value.accounts.map((p) => {
    //         return !PAYTO_REGEX.test(p.payto_uri)
    //           ? i18n.str`Invalid`
    //           : undefined;
    //       }),
    //     ),
    default_pay_delay: !value.default_pay_delay
      ? i18n.str`Required`
      : value.default_wire_transfer_delay !== undefined &&
          value.default_wire_transfer_delay.d_us !== "forever" &&
          value.default_pay_delay.d_us !== "forever" &&
          value.default_pay_delay.d_us > value.default_wire_transfer_delay.d_us
        ? i18n.str`Pay delay can't be greater than wire transfer delay`
        : undefined,
    default_wire_transfer_delay: !value.default_wire_transfer_delay
      ? i18n.str`Required`
      : undefined,
    address: undefinedIfEmpty({
      address_lines:
        value.address?.address_lines && value.address?.address_lines.length > 7
          ? i18n.str`Max 7 lines`
          : undefined,
    }),
    jurisdiction: undefinedIfEmpty({
      address_lines:
        value.address?.address_lines && value.address?.address_lines.length > 7
          ? i18n.str`Max 7 lines`
          : undefined,
    }),
  });

  const hasErrors = errors !== undefined;

  const tokenFormErrors = undefinedIfEmpty<FormErrors<TokenForm>>({
    token:
      tokenForm.accessControl === false
        ? undefined
        : !tokenForm.token
          ? i18n.str`Required`
          : undefined,
    repeat:
      tokenForm.accessControl === false
        ? undefined
        : !tokenForm.repeat
          ? i18n.str`Required`
          : tokenForm.repeat !== tokenForm.token
            ? i18n.str`Doesn't match`
            : undefined,
  });

  const hasTokenErrors = tokenFormErrors === undefined;

  const submit = (): Promise<void> => {
    // use conversion instead of this
    const newValue = structuredClone(value);

    const accessControl = !!tokenForm.accessControl;
    newValue.auth_token = undefined;
    newValue.auth = !accessControl
      ? { method: MerchantAuthMethod.EXTERNAL }
      : {
          method: MerchantAuthMethod.TOKEN,
          token: createRFC8959AccessTokenPlain(tokenForm.token!),
        };
    if (!newValue.address) newValue.address = {};
    if (!newValue.jurisdiction) newValue.jurisdiction = {};

    return onCreate(newValue as TalerMerchantApi.InstanceConfigurationMessage);
  };

  return (
    <div>
      <section class="section is-main-section">
        <div class="tabs is-toggle is-fullwidth is-small">
          <ul>
            <li
              class={!pref.advanceInstanceMode ? "is-active" : ""}
              onClick={() => {
                updatePref("advanceInstanceMode", false);
              }}
            >
              <a>
                <span>
                  <i18n.Translate>Simple</i18n.Translate>
                </span>
              </a>
            </li>
            <li
              class={pref.advanceInstanceMode ? "is-active" : ""}
              onClick={() => {
                updatePref("advanceInstanceMode", true);
              }}
            >
              <a>
                <span>
                  <i18n.Translate>Advanced</i18n.Translate>
                </span>
              </a>
            </li>
          </ul>
        </div>{" "}
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider<Entity>
              errors={errors}
              object={value}
              valueHandler={valueHandler}
            >
              <DefaultInstanceFormFields
                readonlyId={!!forceId}
                showId={!forceId}
                showLessFields={!pref.advanceInstanceMode}
              />
            </FormProvider>
            <FormProvider
              errors={tokenFormErrors}
              object={tokenForm}
              valueHandler={setTokenForm}
            >
              <InputToggle<TokenForm>
                name="accessControl"
                threeState={tokenForm.accessControl === undefined}
                label={i18n.str`Enable access control`}
                help={i18n.str`Choose if the backend server should authenticate access.`}
              />
              <Input<TokenForm>
                name="token"
                label={i18n.str`New access token`}
                tooltip={i18n.str`Next access token to be used`}
                readonly={
                  tokenForm.accessControl === false ||
                  tokenForm.accessControl === undefined
                }
                inputType="password"
              />
              <Input<TokenForm>
                name="repeat"
                label={i18n.str`Repeat access token`}
                tooltip={i18n.str`Confirm the same access token`}
                readonly={
                  tokenForm.accessControl === false ||
                  tokenForm.accessControl === undefined
                }
                inputType="password"
              />
            </FormProvider>
            <div class="level">
              <div class="level-item has-text-centered">
                {tokenForm.accessControl === undefined ? (
                  <p class="is-size-6">
                    <i18n.Translate>
                      Access control is not yet decided. This instance can't be
                      created.
                    </i18n.Translate>
                  </p>
                ) : !tokenForm.accessControl ? (
                  <p class="is-size-6">
                    <i18n.Translate>
                      Authorization must be handled externally.
                    </i18n.Translate>
                  </p>
                ) : (
                  <p class="is-size-6">
                    <i18n.Translate>
                      Authorization is handled by the backend server.
                    </i18n.Translate>
                  </p>
                )}
              </div>
            </div>
            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                onClick={submit}
                disabled={hasErrors || !hasTokenErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields and choose authorization method`
                    : i18n.str`Confirm operation`
                }
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
