import {
  DoubleColumnFormDesign,
  InternationalizationAPI,
} from "../../index.browser.js";
import {
  countryNameList,
  countryNationalityList,
} from "../../utils/select-ui-lists.js";
import { TalerFormAttributes } from "@gnu-taler/taler-util";

export function GLS_Onboarding(
  i18n: InternationalizationAPI,
  context?: any,
): DoubleColumnFormDesign {
  return {
    type: "double-column",
    sections: [
      {
        title: i18n.str`Contact information of the  company representative`,
        // description: i18n.str``,
        fields: [
          {
            id: TalerFormAttributes.PERSON_FULL_NAME,
            label: i18n.str`First name(s)`,
            help: i18n.str`As on your ID document`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.PERSON_LAST_NAME,
            label: i18n.str`Last name`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.PERSON_DATE_OF_BIRTH,
            label: i18n.str`Date of birth`,
            type: "isoDateText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            required: true,
          },
          {
            id: TalerFormAttributes.PERSON_NATIONALITY,
            label: i18n.str`Nationality`,
            type: "selectOne",
            choices: countryNationalityList(i18n),
            required: true,
          },
          {
            type: "toggle",
            id: TalerFormAttributes.ACCEPTED_TERMS_OF_SERVICE,
            required: true,
            // threeState: true,
            label: i18n.str`Do you accept the terms of service?`,
            validator(v) {
              return !v
                ? i18n.str`Can't continue without accepting term of service.`
                : undefined;
            },
          },
          {
            type: "external-link",
            url: "https://google.com",
            label: i18n.str`Read the term of service here`,
            media: "text/plain",
          },
        ],
      },
      {
        title: i18n.str`Business company information`,
        fields: [
          {
            id: TalerFormAttributes.BUSINESS_DISPLAY_NAME,
            label: i18n.str`Name of the company`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.BUSINESS_TYPE,
            label: i18n.str`Business type`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.BUSINESS_REGISTRATION_ID,
            label: i18n.str`Registration ID`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.BUSINESS_LEGAL_JURISDICTION,
            label: i18n.str`Legal jurisdiction`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.BUSINESS_REGISTRATION_DATE,
            label: i18n.str`Registration date`,
            type: "isoDateText",
            placeholder: "dd/MM/yyyy",
            pattern: "dd/MM/yyyy",
            required: true,
          },
          {
            id: TalerFormAttributes.BUSINESS_IS_NON_PROFIT,
            label: i18n.str`Is non profit?`,
            type: "toggle",
            required: true,
          },
          {
            id: TalerFormAttributes.BUSINESS_INDUSTRY,
            label: i18n.str`Industry`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.BUSINESS_LEGAL_REPRESENTATIVES,
            label: i18n.str`Legal representatives`,
            type: "array",
            labelFieldId: TalerFormAttributes.GLS_REPRESENTATIVE_FULL_NAME,
            fields: [
              {
                id: TalerFormAttributes.GLS_REPRESENTATIVE_FULL_NAME,
                label: i18n.str`Full name`,
                type: "text",
                required: true,
              },
              {
                id: TalerFormAttributes.GLS_REPRESENTATIVE_LAST_NAME,
                label: i18n.str`Last name`,
                type: "text",
                required: true,
              },
              {
                id: TalerFormAttributes.GLS_REPRESENTATIVE_DATE_OF_BIRTH,
                label: i18n.str`Date of birth`,
                type: "isoDateText",
                placeholder: "dd/MM/yyyy",
                pattern: "dd/MM/yyyy",
                required: true,
              },
              {
                id: TalerFormAttributes.GLS_REPRESENTATIVE_NATIONALITY,
                label: i18n.str`Nationality`,
                type: "text",
                required: true,
              },
            ],
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Contact information of company headquarters`,
        fields: [
          {
            id: TalerFormAttributes.CONTACT_WEB_DOMAIN,
            label: i18n.str`Web site`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.CONTACT_EMAIL,
            label: i18n.str`Email`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.CONTACT_PHONE,
            label: i18n.str`Phone`,
            type: "text",
            required: true,
          },
        ],
      },
      {
        title: i18n.str`Location information`,
        fields: [
          {
            id: TalerFormAttributes.ADDRESS_COUNTRY,
            label: i18n.str`Country`,
            type: "selectOne",
            choices: countryNameList(i18n),
            required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_COUNTRY_SUBDIVISION,
            label: i18n.str`Country subdivision`,
            type: "text",
            // required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_STREET_NAME,
            label: i18n.str`Street name`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_STREET_NUMBER,
            label: i18n.str`Street number`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_LINES,
            label: i18n.str`Street lines`,
            type: "textArea",
            // required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_BUILDING_NAME,
            label: i18n.str`Building name`,
            type: "text",
            // required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_BUILDING_NUMBER,
            label: i18n.str`Building number`,
            type: "text",
            // required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_ZIPCODE,
            label: i18n.str`Zipcode`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_TOWN_LOCATION,
            label: i18n.str`Town location`,
            type: "text",
            // required: true,
          },
          {
            id: TalerFormAttributes.ADDRESS_TOWN_DISTRICT,
            label: i18n.str`Town district`,
            type: "text",
            // required: true,
          },
        ],
      },
      {
        title: i18n.str`Tax information`,
        fields: [
          {
            id: TalerFormAttributes.TAX_COUNTRY,
            label: i18n.str`Country`,
            type: "selectOne",
            choices: countryNameList(i18n),
            required: true,
          },
          {
            id: TalerFormAttributes.TAX_ID,
            label: i18n.str`ID`,
            type: "text",
            required: true,
          },
          {
            id: TalerFormAttributes.TAX_IS_USA_LAW,
            label: i18n.str`under USA law?`,
            // help: i18n.str`wurde die gesellschaft in den USA oder nach US-Recht gergrudent`,
            type: "toggle",
            required: true,
          },
          {
            id: TalerFormAttributes.TAX_IS_ACTIVE,
            label: i18n.str`is economically active?`,
            // help: i18n.str`wirtschaftlich aktiv oder passiv`,
            type: "toggle",
            required: true,
          },
          {
            id: TalerFormAttributes.TAX_IS_DEDUCTED,
            label: i18n.str`entitled to deduct tax?`,
            // help: i18n.str`sind sie vorsteuerabzugsberechtigt`,
            type: "toggle",
            required: true,
          },
        ],
      },
    ],
  };
}
